import { AuthzHelpers } from '@amp/helpers/authzHelpers';
import { config } from '@amp/config/config';
import { initLibrary } from 'amp-client-config';

export const updateAmpClientConfigData = async () => {
  const axios = new AuthzHelpers().getHttpClient();
  try {
    const { data } = await axios.get(config.formContentUrl);
    if (data) {
      initLibrary(data);
    }
  } catch (err) {
    console.error(err);
  }
};
