import { get } from 'lodash';

import { goals as GoalsForSgma } from './formDataContent/GoalsFormSgma';
import { goals as GoalsForSgmaPayer } from './formDataContent/GoalsFormSgmaPayer';

import { Client, FormType, MixedTeam, MixedSegment } from '../types/client.types';
import { SanofiTeam, SanofiSgmaBrand, SanofiSegment } from '../types/client.sanofi.types';
import { PayerSciencesBrand, PayerSciencesSegment, PayerSciencesTeam } from '../types/client.payersciences.types';
import { JanssenSgmaBrand, JanssenSegment, JanssenTeam } from '../types/client.janssen.types';

export const getOneFormDataByClientTeam = (
  client: Client,
  team: MixedTeam,
  form: FormType,
  brand?: SanofiSgmaBrand | PayerSciencesBrand | JanssenSgmaBrand,
  segmentTypeName?: MixedSegment
): any => {
  switch (client.toUpperCase()) {
  case 'SANOFI':
    return getFormDataForSanofiTeam(team as SanofiTeam, form, brand as SanofiSgmaBrand, segmentTypeName);
  case 'PAYERSCIENCES':
    return getFormDataForPayerSciencesTeam(team as PayerSciencesTeam, form, brand as PayerSciencesBrand, segmentTypeName);
  case 'JANSSEN':
    return getFormDataForJanssenTeam(team as JanssenTeam, form, brand as JanssenSgmaBrand, segmentTypeName);
  default:
    return undefined;
  }
};

/*
 * Sanofi Form Data
 * Legacy
 */

// DO NOT REMOVE UNTIL UI HAS CHANGED FROM form-data FORMS TO THE ACTUAL FORM
export const getFormDataForSanofiTeam = (team: SanofiTeam, form: FormType, brand?: SanofiSgmaBrand,
  segmentTypeName?: SanofiSegment): any => {
  switch (team) {
  case 'SGMA':
    return getOneSanofiSgmaFormData(form, brand, segmentTypeName);
  default:
    return undefined;
  }
};

// DO NOT REMOVE UNTIL UI HAS CHANGED FROM form-data FORMS TO THE ACTUAL FORM
export const getOneSanofiSgmaFormData = (
  form: FormType,
  brand?: SanofiSgmaBrand,
  segmentTypeName?: SanofiSegment
): any => {
  const payersSegments: SanofiSegment[] = ['commercial', 'medicaid', 'medicare'];
  const goals = segmentTypeName && payersSegments.includes(segmentTypeName) ? GoalsForSgmaPayer : GoalsForSgma;

  switch (form) {
  case 'goalsAndObjectives':
    if (brand) {
      return get(goals, brand, {});
    } else {
      return goals;
    }
  default:
    return undefined;
  }
};

/*
 * PayerSciences Form Data
 * Temporal | Legacy
 */

// DO NOT REMOVE UNTIL UI HAS CHANGED FROM form-data FORMS TO THE ACTUAL FORM
export const getFormDataForPayerSciencesTeam = (team: PayerSciencesTeam, form: FormType, brand?: PayerSciencesBrand,
  segmentTypeName?: PayerSciencesSegment): any => {
  switch (team) {
  case 'TEAM 1':
    return getOnePayerSciencesTeam1FormData(form, brand, segmentTypeName);
  default:
    return undefined;
  }
};

// DO NOT REMOVE UNTIL UI HAS CHANGED FROM form-data FORMS TO THE ACTUAL FORM
export const getOnePayerSciencesTeam1FormData = (
  form: FormType,
  brand?: PayerSciencesBrand,
  segmentTypeName?: PayerSciencesSegment
): any => {
  const payersSegments: PayerSciencesSegment[] = ['commercial', 'medicaid', 'medicare'];
  const goals = segmentTypeName && payersSegments.includes(segmentTypeName) ? GoalsForSgmaPayer : GoalsForSgma;

  switch (form) {
  case 'goalsAndObjectives':
    if (brand) {
      return get(goals, brand, {});
    } else {
      return goals;
    }
  default:
    return undefined;
  }
};

/*
 * Janssen Form Data
 * Temporal | Legacy
 */

// DO NOT REMOVE UNTIL UI HAS CHANGED FROM form-data FORMS TO THE ACTUAL FORM
export const getFormDataForJanssenTeam = (team: JanssenTeam, form: FormType, brand?: JanssenSgmaBrand,
  segmentTypeName?: JanssenSegment): any => {
  switch (team) {
  case 'SGMA':
    return getOneJanssenSgmaFormData(form, brand, segmentTypeName);
  default:
    return undefined;
  }
};

// DO NOT REMOVE UNTIL UI HAS CHANGED FROM form-data FORMS TO THE ACTUAL FORM
export const getOneJanssenSgmaFormData = (
  form: FormType,
  brand?: JanssenSgmaBrand,
  segmentTypeName?: JanssenSegment
): any => {
  const payersSegments: JanssenSegment[] = ['commercial', 'medicaid', 'medicare'];
  const goals = segmentTypeName && payersSegments.includes(segmentTypeName) ? GoalsForSgmaPayer : GoalsForSgma;

  switch (form) {
  case 'goalsAndObjectives':
    if (brand) {
      return get(goals, brand, {});
    } else {
      return goals;
    }
  default:
    return undefined;
  }
};
