import gql from 'graphql-tag';

export const GET_REPORTS_FROM_USER = gql`
  query GetReportsByUser {
    GetReportsByUser {
      id
      userId
      reportType
      filters
      status
      errorMessage
      url
      reportFile
      tabKey
      createdAt
      updatedAt
      accountSegmentType {
        id
        account {
          accountType {
            name
          }
        }
        sharedAccount {
          accounts {
            accountType {
              name
            }
          }
        }
        segmentType {
          name
        }
      }
    }
  }
`;
