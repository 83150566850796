import { get, isEmpty, some } from 'lodash';

import { IAnswer, ISTRuleSet, ISTStrategy, PartialCodeType } from '../types/format.types';
import { IInfluencerDO, ISTBasedOnInfluencerAnswersModel } from '../types/influencer.types';

import {
  groupByStrategiesWithInfluencers,
  StrategyCalculator,
  StrategyCodeToInfluencers
} from './strategies.helper';

export type DataType = 'OBJECT' | 'ARRAY' | 'STRING' | 'NUMBER' | 'BOOLEAN' | 'MIXED-ARRAY' | 'DATE';

export const doesPartialCodeTypeMatch = (
  partialCodeType: PartialCodeType,
  questionCode: string,
  codeToCheck: string
): boolean => {
  switch (partialCodeType) {
  case 'PREFIX': {
    return codeToCheck.startsWith(questionCode);
  }
  case 'SUFFIX': {
    return codeToCheck.endsWith(questionCode);
  }
  case 'INCLUDES': {
    return codeToCheck.includes(questionCode);
  }
  default:
    return false;
  }
};

export const getAllAnswerCodes = (
  answers: IAnswer[] | undefined
): string[] | undefined => {
  if (!answers || !answers.length) {
    return undefined;
  }
  return answers.map(a => a.code);
};

export const getMatchingStrategiesByCode = (
  strategyCodeToMatch: string,
  ruleSets: ISTRuleSet[]
): ISTStrategy[] => {
  if (!ruleSets) {
    return [];
  }
  const matchingStrategies: ISTStrategy[] = [];
  for (const ruleSet of ruleSets) {
    if (!ruleSet.strategies) {
      continue;
    }
    matchingStrategies.push(...getMatchingStrategiesFromStrategies(
      strategyCodeToMatch,
      ruleSet.strategies
    ));
  }
  return matchingStrategies;
};

export const getMatchingStrategiesFromStrategies = (
  strategyCodeToMatch: string,
  strategies: ISTStrategy[]
): ISTStrategy[] => {
  if (!strategies) {
    return [];
  }
  const matchingStrategies: ISTStrategy[] = [];
  for (const strategy of Object.values(strategies)) {
    if (strategy.code === strategyCodeToMatch) {
      matchingStrategies.push(strategy);
    }
  }
  return matchingStrategies;
};

export const doesTacticCodeMatch = (
  ruleSetStrategies: ISTStrategy[],
  tacticCodeToMatch: string
): boolean => {
  if (!ruleSetStrategies || !ruleSetStrategies.length) {
    return false;
  }
  for (const strategy of ruleSetStrategies) {
    if (!strategy.tactics) {
      continue;
    }
    for (const tactic of strategy.tactics) {
      if (tactic.code === tacticCodeToMatch) {
        return true;
      }
    }
  }
  return false;
};

export const getStrategiesBasedOnInfluencerAnswers = (
  config: ISTBasedOnInfluencerAnswersModel,
  influencers: IInfluencerDO[]
): StrategyCodeToInfluencers => {
  const strategiesTiedToInfluencers = [];

  const { form } = config;

  if (!form) {
    return {};
  }

  const stategyCalculator = StrategyCalculator.create(config);
  const enabledInfluencers = influencers.filter(influencer => influencer.enabled);
  const formQuestions = form?.questions?.filter(question => question.viewType === 'matrix-radio') || [];

  if (isEmpty(formQuestions)) {
    return {};
  }

  for (const influencer of enabledInfluencers) {
    for (const question of formQuestions) {
      if (!influencer || !question) {
        continue;
      }

      const data = stategyCalculator.getInfluencerData(influencer, question);
      const foundRuleSet = stategyCalculator.getStrategies(data, question, influencer);

      if (!foundRuleSet || isEmpty(foundRuleSet)) {
        continue;
      }

      const addedStrategies = foundRuleSet.strategies.map((strategy: any) => ({
        strategyCode: strategy.code,
        influencer
      }));

      strategiesTiedToInfluencers.push(...addedStrategies);
    }
  }

  return groupByStrategiesWithInfluencers(strategiesTiedToInfluencers);
};

export const shouldFilterStrategyWithDataFilterRules = (matchingRule: ISTRuleSet, influencer: IInfluencerDO, brandId: string | undefined | null) => {
  const { dataFilterRules } = matchingRule;
  if (!dataFilterRules) {
    return false;
  }

  return some(dataFilterRules, filterRule => {
    const path = brandId ? filterRule.path.replace('{{brand.id}}', brandId) : filterRule.path;
    const data = get(influencer, path);
    return !filterRule.in.includes(data);
  });
};
