import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { cloneDeep, isEmpty, uniq } from 'lodash';

import { IDownloadItem, IReportMessage, IReportsByUserQuery } from '@amp/components/page-layout/header/downloads-popup/DownloadsPopup.types';
import { GET_REPORTS_FROM_USER } from '@amp/graphql/queries/Reports';
import { RootContext } from '@amp/contexts/RootContext';
import { subscribeToReportStatusWebPubSub } from '@amp/helpers/webPubSubHelper';
import { UnsuccessfulRequestModal } from '@amp/components/common/modals/unsuccessful-request-modal/UnsuccessfulRequestModal';
import { downloadResource, getDownloadTitle } from '@amp/helpers/download';
import { AuthzHelpers } from '@amp/helpers/authzHelpers';
import { config } from '@amp/config/config';
import { SESSION_TAB_KEY } from '@amp/contexts/RootContext.helper';

const REPORTS_TO_DOWNLOAD_ON_REFRESH_KEY = 'reportIdsToDownloadLocally';

export const DownloadReportsContextProvider: React.FC = ({ children }) => {
  const axios = new AuthzHelpers().getHttpClient();
  const { isOnline } = useContext(RootContext);

  const [showUnsuccessfulModal, setShowUnsuccessfulModal] = useState(false);
  const [unsuccessfulReportId, setUnsuccessfulReportId] = useState<string>();
  const [reportIdsToDownload, setReportIdsToDownload] = useState<string[]>([]);
  const [inProgressItems, setInProgressItems] = useState<IDownloadItem[]>([]);
  const [shouldDownloadMissingItems, setShouldDownloadMissingItems] = useState(false);

  const { data: userDownloadItemsData, refetch: refetchUserDownloadItems } = useQuery<IReportsByUserQuery>(
    GET_REPORTS_FROM_USER,
    { notifyOnNetworkStatusChange: true }
  );

  const tabKey = sessionStorage.getItem(SESSION_TAB_KEY);

  const downloadReportUrlAfterTimeout = useCallback(({ reportId, reportUrl, reportFileName, timeout }: {
    reportId: string, reportUrl?: string, reportFileName?: string, timeout: number
  }) => {
    if (!reportUrl) {
      return;
    }

    const timeoutIdentifier: ReturnType<typeof setTimeout> = setTimeout(() => {
      downloadResource(reportUrl, reportFileName!);
      removeReportIdFromLocalStorage(reportId);
      setReportIdsToDownload(oldReportIds => {
        const newReportIds = cloneDeep(oldReportIds) || [];
        const reportIdIndex = newReportIds.indexOf(reportId);
        newReportIds.splice(reportIdIndex, 1);
        return uniq(newReportIds);
      });
    }, timeout);
    return timeoutIdentifier;
  }, []);

  const userDownloadItems: IDownloadItem[] = useMemo(() => {
    const allItems = userDownloadItemsData?.GetReportsByUser || [];
    if (isEmpty(allItems)) {
      return [];
    }

    return cloneDeep(allItems).map(item => {
      item.reportTitle = getDownloadTitle(item);
      return item;
    });
  }, [userDownloadItemsData]);

  const unsuccessfulReportItem = useMemo(() => {
    if (!unsuccessfulReportId) {
      return null;
    }
    return userDownloadItems.find(item => item.id === unsuccessfulReportId);
  }, [unsuccessfulReportId, userDownloadItems]);

  useEffect(() => {
    if (!isOnline || isEmpty(userDownloadItems)) {
      return;
    }

    const reportIdsToDownloadOnRefresh = JSON.parse(localStorage.getItem(REPORTS_TO_DOWNLOAD_ON_REFRESH_KEY) ?? '[]');
    if (isEmpty(reportIdsToDownloadOnRefresh)) {
      return;
    }

    const reportIdsToDownload: string[] = [];
    for (const reportId of reportIdsToDownloadOnRefresh) {
      const reportItem = userDownloadItems.find(item => item.id === reportId);
      if (reportItem?.status === 'Finished') {
        reportIdsToDownload.push(reportId);
      }
    }
    setReportIdsToDownload(oldReportIds => uniq([
      ...oldReportIds,
      ...reportIdsToDownload
    ]));
  }, [isOnline, userDownloadItems]);

  useEffect(() => {
    if (!shouldDownloadMissingItems) {
      return;
    }
    const newFinishedDownloads = userDownloadItems.filter(item => item.status === 'Finished');
    const itemsToDownload = newFinishedDownloads.filter(item => inProgressItems.map(inProgressItem => inProgressItem.id).includes(item.id));
    setReportIdsToDownload(oldReportIds => {
      const newReportIds = cloneDeep(oldReportIds);
      for (const report of itemsToDownload) {
        if (!newReportIds.includes(report.id)) {
          newReportIds.push(report.id);
        }
      }
      return uniq(newReportIds);
    });
    setShouldDownloadMissingItems(false);
    // This should only be executed when the list of items finish updating
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDownloadItems]);

  useEffect(() => {
    if (!isOnline || shouldDownloadMissingItems) {
      return;
    }
    const inProgressItems = userDownloadItems.filter(item => item.status === 'Starting' || item.status === 'In Progress');
    if (isEmpty(inProgressItems)) {
      return;
    }
    setShouldDownloadMissingItems(true);
    setInProgressItems(inProgressItems);
    refetchUserDownloadItems().catch(() => {
      setShouldDownloadMissingItems(false);
      setInProgressItems([]);
    });
    // This should only be executed when the browser is back online
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  useEffect(() => {
    if (isEmpty(reportIdsToDownload)) {
      return;
    }
    const timeouts: (ReturnType<typeof setTimeout>)[] = [];
    const reportIdsToBeDownloaded = cloneDeep(reportIdsToDownload) || [];

    let timeout = 5000;
    while (!isEmpty(reportIdsToBeDownloaded)) {
      const reportId = reportIdsToBeDownloaded.pop() || '';
      const report = userDownloadItems.find(item => item.id === reportId && item.tabKey === tabKey);
      if (!report) {
        continue;
      }
      const timeoutIdentifier = downloadReportUrlAfterTimeout({
        reportId,
        reportUrl: report?.url,
        reportFileName: report?.reportFile,
        timeout
      });
      if (timeoutIdentifier) {
        timeouts.push(timeoutIdentifier);
      }
      timeout += 5000;
    }
    return () => {
      timeouts.map(timeoutIdentifier => clearTimeout(timeoutIdentifier));
    };
  }, [downloadReportUrlAfterTimeout, userDownloadItems, reportIdsToDownload, tabKey]);

  const removeReportIdFromLocalStorage = (reportId: string): void => {
    const reportIdsToDownloadOnRefresh = JSON.parse(localStorage.getItem(REPORTS_TO_DOWNLOAD_ON_REFRESH_KEY) ?? '[]');
    const i = reportIdsToDownloadOnRefresh.indexOf(reportId);
    if (i >= 0) {
      reportIdsToDownloadOnRefresh.splice(i, 1);
      localStorage.setItem(REPORTS_TO_DOWNLOAD_ON_REFRESH_KEY, JSON.stringify(reportIdsToDownloadOnRefresh));
    }
  };

  const storeReportIdLocallyToDownloadOnRefresh = (reportId: string): void => {
    const reportIdsToDownloadOnRefresh = JSON.parse(localStorage.getItem(REPORTS_TO_DOWNLOAD_ON_REFRESH_KEY) ?? '[]');
    reportIdsToDownloadOnRefresh.push(reportId);
    localStorage.setItem(REPORTS_TO_DOWNLOAD_ON_REFRESH_KEY, JSON.stringify(uniq(reportIdsToDownloadOnRefresh)));
  };

  const processReportStatusMessage = async (message: MessageEvent) => {
    await refetchUserDownloadItems();
    const reportMessage: IReportMessage = JSON.parse(message.data);
    if (reportMessage.status === 'Finished') {
      setReportIdsToDownload(oldReportIds => {
        const newReportIds = cloneDeep(oldReportIds) || [];
        newReportIds.push(reportMessage.reportId);
        return uniq(newReportIds);
      });
    } else if (reportMessage.status === 'Failed') {
      setUnsuccessfulReportId(reportMessage.reportId);
      setShowUnsuccessfulModal(true);
      removeReportIdFromLocalStorage(reportMessage.reportId);
    } else {
      storeReportIdLocallyToDownloadOnRefresh(reportMessage.reportId);
    }
  };

  const processReportStatusError = async (err: any) => {
    console.error(err);
  };

  useEffect(() => {
    if (isOnline) {
      subscribeToReportStatusWebPubSub({
        processMessage: processReportStatusMessage,
        processError: processReportStatusError
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  const onUnsuccessfulModalClose = () => {
    setUnsuccessfulReportId(undefined);
    setShowUnsuccessfulModal(false);
  };

  const showReportFailedMessage = (reportId: string): void => {
    setUnsuccessfulReportId(reportId);
    setShowUnsuccessfulModal(true);
  };

  const tryAgain = async () => {
    await axios.get(`${config.tryReportAgainUrl}/${unsuccessfulReportId}`);
    setUnsuccessfulReportId(undefined);
    setShowUnsuccessfulModal(false);
  };

  return <DownloadReportsContext.Provider value={{
    refetchUserDownloadItems,
    showReportFailedMessage,
    userDownloadItems
  }}>
    <UnsuccessfulRequestModal
      isOpened={showUnsuccessfulModal}
      onCancel={onUnsuccessfulModalClose}
      modalText={`Error generating report ${unsuccessfulReportItem?.reportTitle}`}
      errorMessage={unsuccessfulReportItem?.errorMessage}
      allowTryAgain={true}
      onTryAgain={tryAgain}
      showScreenshotMessage={false}
    />
    {children}
  </DownloadReportsContext.Provider>;
};

export const DownloadReportsContext = React.createContext<IDownloadReportsContext>({
  refetchUserDownloadItems: () => undefined,
  showReportFailedMessage: () => undefined,
  userDownloadItems: []
});

export interface IDownloadReportsContext {
  refetchUserDownloadItems: () => void;
  showReportFailedMessage: (reportId: string) => void;
  userDownloadItems: IDownloadItem[];
}
