import { w3cwebsocket as W3CWebSocket } from 'websocket';

import { authzHelpers } from '@amp/helpers/authzHelpers';
import { config } from '@amp/config/config';

export const subscribeToReportStatusWebPubSub = async ({ processError, processMessage }: {
  processError: (error: any) => Promise<void>,
  processMessage: (message: any) => Promise<void>
}): Promise<void> => {
  await subscribeToWebPubSub({ configUrl: config.reportStatusWebsocketUrl, processError, processMessage });
};

export const subscribeToWebpConvertedWebPubSub = async ({ processError, processMessage }: {
  processError: (error: any) => Promise<void>,
  processMessage: (message: any) => Promise<void>
}): Promise<W3CWebSocket> => {
  return subscribeToWebPubSub({ configUrl: config.webpGeneratedWebsocketUrl, processError, processMessage });
};

const subscribeToWebPubSub = async ({ configUrl, processError, processMessage }: {
  configUrl: string,
  processError: (error: any) => Promise<void>,
  processMessage: (message: any) => Promise<void>,
}): Promise<W3CWebSocket> => {
  const httpClient = authzHelpers.getHttpClient();
  const response = await httpClient.get(configUrl);

  const url: string = response.data;
  const wsClient = new W3CWebSocket(url);

  wsClient.onerror = processError;
  wsClient.onmessage = processMessage;

  return wsClient;
};
