import { onError } from '@apollo/client/link/error';
import { isEmpty, isEqual } from 'lodash';

import ampVersion from '../amp-version.json';

import { IFlashMessageFunctions } from '@amp/hooks/useFlashMessage';

export const SESSION_TAB_KEY = 'tabKey';

export const filterBadRequest = ({ status }: any) => status >= 400 && status <= 500;

export const setVersion = () => {
  if (ampVersion.version === 'local') {
    return ampVersion.version;
  }
  return `v${ampVersion.version}`;
};

export const handleNetworkError = (errorMessage: string, dispatch: Function, isOnline: boolean) => {
  const previousError = localStorage.getItem('networkError');

  if (previousError && errorMessage === previousError) {
    return;
  }

  localStorage.setItem('networkError', errorMessage);
  setTimeout(() => localStorage.removeItem('networkError'), 500);

  if (isOnline) {
    dispatch('Something went wrong. Please reload the page.');
  }
};

export const getErrorHandler = (
  handleLogout: (() => void),
  { flashWarning, flashInfo }: IFlashMessageFunctions
) => {
  return onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      handleNetworkError(networkError.message, flashWarning, navigator.onLine);
    }

    const exceptionErrors = (graphQLErrors || [])
      .map(error => ({
        code: error?.extensions?.code,
        message: error?.message,
        type: (error?.extensions as any)?.exception?.name,
        status: (error?.extensions as any)?.exception?.status,
        path: error?.path
      }))
      .filter(exceptionError => exceptionError?.code);

    if (!isEmpty(exceptionErrors)) {
      const unauthorizedError = exceptionErrors.some(e => e.code === 'UNAUTHORIZED' || e.code === 'UNAUTHENTICATED');
      const passwordHasExpired = exceptionErrors.some(e => isEqual(e, ({ code: 'INTERNAL_SERVER_ERROR', message: 'Password expired' })));
      const internalServerErrors = exceptionErrors.filter(e => e.code === 'INTERNAL_SERVER_ERROR' && !e.type);
      const silentErrors = exceptionErrors.filter(e => e.code === 'INTERNAL_SERVER_ERROR' && e.type === 'SeeOtherError');

      if (passwordHasExpired && window.location.pathname !== '/change-password') {
        window.location.pathname = '/change-password';
      }

      if (unauthorizedError) {
        if (localStorage.getItem('token')) {
          flashInfo('Please login again.');
        }
        console.log('Apollo logout');
        handleLogout();
        return;
      }

      if (!isEmpty(internalServerErrors)) {
        console.error(internalServerErrors);

        return;
      }

      const badRequest = exceptionErrors.some(filterBadRequest);

      if (badRequest) {
        return;
      }

      if (silentErrors) {
        return;
      }

      // eslint-disable-next-line no-console
      console.warn({ errors: exceptionErrors });
    }
  });
};

export const createOrSetTabKey = (tabKey: string, setTabKey: (id: string) => void) => {
  if (tabKey) {
    return;
  }

  const sessionTabKey = sessionStorage.getItem(SESSION_TAB_KEY);

  if (sessionTabKey) {
    setTabKey(sessionTabKey);
  } else {
    const newTabKey = Math.round(Math.random() * 10000000000).toString();

    sessionStorage.setItem(SESSION_TAB_KEY, newTabKey);
    setTabKey(newTabKey);
  }
};
