import { FC, lazy, Suspense } from 'react';
import { Switch, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { AdminRoute } from './AdminRoute';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { RoutePaths } from './route-paths';

/* NOTE: Do not lazy load NotificationsAdmin as it causes
         a compilation error with mini-css-extract-plugin */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import { NotificationsAdmin } from '../admin/notifications-admin/NotificationsAdmin';

const DVA = lazy(() => import('./default-exports/dva'));
const EventTracker = lazy(() => import('./default-exports/event-tracker'));
const Planning = lazy(() => import('./default-exports/planning'));
const Profiling = lazy(() => import('./default-exports/profiling'));

const Dashboard = lazy(() => import('./default-exports/dashboard'));
const Login = lazy(() => import('./default-exports/login'));
const BlankPageLayout = lazy(() => import('./default-exports/blank-page-layout'));
const ResetPasswordPage = lazy(() => import('./default-exports/reset-password-page'));

const AccountAlignmentAdmin = lazy(() => import('./default-exports/account-alignment-admin'));
const FeatureAdmin = lazy(() => import('./default-exports/feature-admin'));
const SurveyAdmin = lazy(() => import('./default-exports/survey-admin'));
const TacticAdmin = lazy(() => import('./default-exports/tactic-admin'));
const UserAdmin = lazy(() => import('./default-exports/user-admin'));

export const Routes: FC = () => {
  return (
    <>
      <Suspense fallback={<>...</>}>
        <BrowserRouter>
          <Switch>
            <PublicRoute exact path={RoutePaths.Login} component={Login} layout={BlankPageLayout} />
            <PublicRoute
              exact
              path={RoutePaths.ResetPassword}
              component={() => <ResetPasswordPage isChangeExpiredPasswordScreen={false} />}
              layout={BlankPageLayout}
            />
            <PrivateRoute
              exact
              path={RoutePaths.ChangeExpiredPassword}
              Component={() => <ResetPasswordPage isChangeExpiredPasswordScreen={true} />}
              Layout={BlankPageLayout}
            />
            <PrivateRoute exact path={RoutePaths.Dashboard} Component={Dashboard} />
            <PrivateRoute path={RoutePaths.Planning} Component={Planning} />

            <PrivateRoute path={RoutePaths.Profiling} Component={Profiling} />
            <AdminRoute path={RoutePaths.UserAdmin} component={UserAdmin} />
            <AdminRoute path={RoutePaths.SurveyAdmin} component={SurveyAdmin} />

            <PrivateRoute path={RoutePaths.DVA} Component={DVA} />
            <PrivateRoute path={RoutePaths.EventTracker} Component={EventTracker} />
            <AdminRoute path={RoutePaths.TacticAdmin} component={TacticAdmin} />
            {/* @TODO: AMPD-2203 to disable notifications, to reinstate, uncomment */}
            {/* <AdminRoute path={RoutePaths.NotificationsAdmin} component={NotificationsAdmin} /> */}
            <AdminRoute path={RoutePaths.AccountAlignmentAdmin} component={AccountAlignmentAdmin} />
            <PrivateRoute path={RoutePaths.FeatureAdmin} Component={FeatureAdmin} />
            <Redirect from="/" to="/home" />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </>
  );
};
