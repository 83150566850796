export enum RoutePaths {
  Dashboard = '/home',
  Planning = '/planning',
  Profiling = '/profiling',
  DVA = '/dva',
  EventTracker = '/event-tracker',
  TacticAdmin = '/tactics-admin',
  FeatureAdmin = '/manage-features',
  UserAdmin = '/users-admin',
  /**
   * @TODO: AMPD-2203 to disable notifications, to reinstate, uncomment the next one line of code
   */
  // NotificationsAdmin = '/notifications-admin',
  SurveyAdmin = '/surveys-admin',
  Login = '/login',
  ResetPassword = '/reset-password',
  ChangeExpiredPassword = '/change-password',
  AccountAlignmentAdmin = '/account-alignment-admin'
}
