export const clearExtraSpaces = (value?: string): string | undefined => {
  if (!value) {
    return;
  }

  return value.trim().replace(/[\t ]+/g, ' ');
};

/**
 * @param value string
 * @returns uppercase the first letter of the first word
 */
export const upperCaseFirstLetter = (value: string | undefined) => {
  if (!value) { return ''; }
  const firstLetter = value.charAt(0).toUpperCase();
  const remainingLetters = value.slice(1).toLowerCase();
  return firstLetter + remainingLetters;
};

export const upperCaseFirstManyWords = (text: string | undefined) => {
  if (!text) { return ''; }
  return text.toLowerCase().split(' ').map(value => upperCaseFirstLetter(value)).join(' ');
};
