import { offlineCache } from '@amp/offline-cache/OfflineCache';
import { CacheEntity } from '@amp/offline-cache/OfflineCache.types';
import { IClient } from '@amp/config/config.types';
import axios from 'axios';

class Config {
  ampVersionFileUrl: string;
  apiUrl: string;
  loginUrl: string;
  graphqlUrl: string;
  reportUrl: string;
  profilingUrl: string;
  tacticsUrl: string;
  eventTrackerReportUrl: string;
  healthzUrl: string;
  analyticsUrl: string;
  appInsightsUrl: string;
  clientConfigUrl: string;
  offlineTransactionsUrl: string;
  accountAlignmentUrl: string;
  uploadBlobUrl: string;
  sendNotificationUrl: string;
  reportStatusWebsocketUrl: string;
  webpGeneratedWebsocketUrl: string;
  tryReportAgainUrl: string;
  appEnv = '';
  magicBellApiKey = '';
  featureUrl: string;
  allFeaturesUrl: string;
  formContentUrl: string;
  origin: string;
  permissionUrl: string;
  client = '';
  appInsightsKey = '';

  constructor () {
    if (location.origin === 'http://webapp-e2e:3000') {
      this.origin = 'http://api-e2e:3030';
    } else if (location.origin.includes(':3000')) {
      this.origin = location.origin.replace(':3000', ':3030');
    } else {
      this.origin = location.origin;
    }

    this.ampVersionFileUrl = `${this.origin}/amp-version.json`;
    this.featureUrl = `${this.origin}/api/features`;
    this.allFeaturesUrl = `${this.origin}/api/features/all`;
    this.formContentUrl = `${this.origin}/api/form-content`;
    this.permissionUrl = `${this.origin}/api/permissions`;
    this.apiUrl = `${this.origin}/api`;
    this.loginUrl = `${this.apiUrl}/login`;
    this.graphqlUrl = `${this.apiUrl}/graphql`;
    this.reportUrl = `${this.apiUrl}/reports`;
    this.profilingUrl = `${this.apiUrl}/profiling`;
    this.tacticsUrl = `${this.apiUrl}/tactics`;
    this.eventTrackerReportUrl = `${this.apiUrl}/event-tracker-reports`;
    this.healthzUrl = `${this.apiUrl}/healthz`;
    this.analyticsUrl = `${this.apiUrl}/analytics`;
    this.appInsightsUrl = `${this.apiUrl}/analytics/track-app-insights`;
    this.clientConfigUrl = `${this.apiUrl}/config/client`;
    this.uploadBlobUrl = `${this.apiUrl}/upload-blob-url`;
    this.sendNotificationUrl = `${this.apiUrl}/push-notification`;
    this.accountAlignmentUrl = `${this.apiUrl}/account-alignment`;
    this.offlineTransactionsUrl = `${this.apiUrl}/offline-transactions`;
    this.reportStatusWebsocketUrl = `${this.apiUrl}/web-pubsub/report-status-socket-url`;
    this.tryReportAgainUrl = `${this.apiUrl}/reports/try-report-again`;
    this.webpGeneratedWebsocketUrl = `${this.apiUrl}/web-pubsub/webp-generated-socket-url`;
  }

  async getClient (): Promise<IClient | undefined> {
    let data;

    try {
      const response = await axios.get(config.clientConfigUrl);
      if (response?.status >= 400) {
        throw new Error('Error when fetching client data');
      }
      data = response?.data;
    } catch (err) {
      console.log(err);
    }

    const client: IClient = offlineCache.cacheQueryEntity(CacheEntity.client, data);
    return client;
  }

  async init () {
    const client = await this.getClient();
    if (!client) {
      throw new Error('Client not initialized!');
    }

    this.client = client.client;
    this.appEnv = client.appEnv || '';
    this.appInsightsKey = client.appInsightsKey || '';
    this.magicBellApiKey = client.magicBellApiKey || '';
  }
}

export const config = new Config();
