import React, { useContext } from 'react';
import { Redirect } from 'react-router';

import { RootContext } from '@amp/contexts/RootContext';
import { PrivateRoute } from './PrivateRoute';
import { RoutePaths } from './route-paths';
import { useFlashMessage } from '@amp/hooks/useFlashMessage';

export const AdminRoute = ({ component: Component, ...rest }: any) => {
  const { isAuthenticatedUserAdmin, isAuthenticated } = useContext(RootContext);
  const { flashError } = useFlashMessage();

  if (isAuthenticated && !isAuthenticatedUserAdmin()) {
    flashError('It\'s not allowed');
    return <Redirect to={RoutePaths.Dashboard} />;
  }

  return <PrivateRoute Component={Component} {...rest} />;
};
