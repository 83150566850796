/* eslint no-restricted-globals: ["off", "location"] */
import 'semantic-ui-less/semantic.less';
import './styles/utils.scss';
import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { config } from './config/config';
import { App } from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initAppInsights } from './app-insights';
import { registerSurveyQuestionDatepicker } from './components/dashboard/profiling/survey-question-datepicker/SurveyQuestionDatepicker.helpers';

registerSurveyQuestionDatepicker();

config.init().then(() => {
  initAppInsights();
}).finally(() => {
  ReactDOM.render(<App />, document.getElementById('root'));
});

// @TODO AMPD-2142 Fix during ServiceWorker refactor
// serviceWorkerHelpers.startCheckingLatestAmpVersion();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// @TODO AMPD-2142 Fix during ServiceWorker refactor
// serviceWorkerRegistration.register({
//   onUpdate: () => location.reload()
// });
serviceWorkerRegistration.unregister();
