export const goals = {
  Nexviazyme: [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        { text: 'Coverage to Label', value: 'coverage_to_label' },
        { text: 'Not Covered (Medical Exception)', value: 'not_covered' },
        { text: 'Currently Seeking to Determine', value: 'wip' }
      ],
      items_target: [
        { text: 'Coverage to Label', value: 'coverage_to_label' }
      ]
    }
  ],
  'Dupixent AD': [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [{ text: 'Covered', value: 'Covered' }, { text: 'Not Covered', value: 'Not Covered' }]
    },
    {
      code: 'priorAuthorization',
      label: 'Prior Authorization',
      items: [
        { text: 'No PA', value: 'No PA' },
        {
          text: 'PA - severity by physician attestation',
          value: 'PA - severity by physician attestation'
        },
        { text: 'PA - severity by BSA', value: 'PA - severity by BSA' },
        { text: 'PA - severity by scoring tool', value: 'PA - severity by scoring tool' }
      ]
    },
    {
      code: 'stepRequirements',
      label: 'Step Requirements',
      items: [
        { text: 'SSE (1 Topical)', value: 'SSE (1 Topical)' },
        { text: 'DSE (2 Topicals)', value: 'DSE (2 Topicals)' },
        { text: 'TSE (3 Topicals)', value: 'TSE (3 Topicals)' },
        {
          text: 'DSE (Includes a phototherapy and/or Immunosuppressant)',
          value: 'DSE (Includes a phototherapy and/or Immunosuppressant)'
        },
        {
          text: 'TSE (Includes a phototherapy and/or Immunosuppressant)',
          value: 'TSE (Includes a phototherapy and/or Immunosuppressant)'
        },
        { text: 'QSE+', value: 'QSE+' },
        { text: 'Not Covered', value: 'Not Covered' }
      ]
    },
    {
      code: 'coverage_competition',
      label: 'Relative Coverage vs AD Competition',
      items: [
        { text: 'All products parity', value: 'all_parity' },
        { text: 'Preferred 1 of 4 w/bio step', value: '1_of_4' },
        { text: 'Preferred 1 of 3 w/bio step', value: '1_of_3' },
        { text: 'Preferred 1 of 2 w/bio step', value: '1_of_2' },
        { text: 'Preferred 1 of 1 w/bio step', value: '1_of_1' }
      ]
    }
  ],
  'Dupixent Asthma': [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered',
          text: 'Covered'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'prior_authorization',
      label: 'Prior Authorization',
      items: [
        {
          value: 'less_restrictive',
          text: 'PA less restrictive than label'
        },
        {
          value: 'PA_to_label',
          text: 'PA to label'
        },
        {
          value: 'more_restrictive',
          text: 'PA more restrictive than label'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'EOS_restrictions',
      label: 'EOS Restrictions',
      items: [
        {
          value: 'no_eos',
          text: 'No EOS restriction'
        },
        {
          value: 'eos_150',
          text: 'EOS >=150 cells/μL'
        },
        {
          value: 'eos_300',
          text: 'EOS >=300 cells/μL'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'exacerbation_requirements',
      label: 'Exacerbation Requirements',
      items: [
        {
          value: 'no_exacerbations',
          text: 'No exacerbations required'
        },
        {
          value: '1_exacerbation',
          text: 'At least 1 exacerbation required'
        },
        {
          value: '2_exacerbations',
          text: '2 or more exacerbations required'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'OCS_requirements',
      label: 'OCS Requirements',
      items: [
        {
          value: 'ocs_type_not_mentioned',
          text: 'OCS-dependent patient type not mentioned'
        },
        {
          value: 'ocs_no_restrictions',
          text: 'OCS-dependent patient type recognized with no further restrictions'
        },
        {
          value: 'ocs_with_restrictions',
          text: 'OCS-dependent patient type recognized with restrictions '
        },
        {
          value: 'ocs_not_recognized',
          text: 'OCS-dependent patient type not recognized'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'relative_coverage',
      label: 'Relative Coverage',
      items: [
        {
          value: 'all_parity',
          text: 'All products parity'
        },
        {
          value: '1_of_4',
          text: 'Preferred 1 of 4 w/bio step'
        },
        {
          value: '1_of_3',
          text: 'Preferred 1 of 3 w/bio step'
        },
        {
          value: '1_of_2',
          text: 'Preferred 1 of 2 w/bio step'
        },
        {
          value: '1_of_1',
          text: 'Preferred 1 of 1 w/bio step'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: '6_11_population',
      label: 'Coverage for indication in 6-11 year old population',
      items: [
        {
          value: 'label_ext',
          text: 'Label Extension'
        },
        {
          value: 'less_restrictive',
          text: 'Less restrictive than current label'
        },
        {
          value: 'more_restrictive',
          text: 'More restrictive than current label'
        },
        {
          value: 'unknown',
          text: 'Unknown'
        }
      ]
    }
  ],
  'Dupixent NP': [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered',
          text: 'Covered'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'indication',
      label: 'Indication',
      items: [
        {
          value: 'crsnp_only',
          text:
            'Add-on maintenance treatment in adult patients with inadequately controlled CRSwNP.'
        },
        {
          value: 'asthma',
          text:
            // tslint:disable-next-line: max-line-length
            'Add-on maintenance treatment of comorbid asthma in adult patients with inadequately controlled CRSwNP.'
        },
        {
          value: 'na',
          text: 'Not Applicable'
        }
      ]
    },
    {
      code: 'Specialist',
      label: 'Specialist',
      items: [
        {
          value: 'alpulemt',
          text: 'Must be prescribed by an Allergist, pulmonologist, or ENT.'
        },
        {
          value: 'ENT',
          text: 'Must be prescribed by an ENT.'
        },
        {
          value: 'na',
          text: 'Not Applicable'
        }
      ]
    },
    {
      code: 'diagnosis',
      label: 'Diagnosis Criteria',
      items: [
        {
          value: 'endoct',
          text: 'CRSwNP confirmed by endoscopy or CT scan'
        },
        {
          value: 'endo',
          text: 'CRSwNP confirmed by endoscopy only'
        },
        {
          value: 'scoring',
          text: 'Requires submission of symptom and/or nasal polyps scoring'
        },
        {
          value: 'na',
          text: 'Not Applicable'
        }
      ]
    },
    {
      code: 'Step_Therapy',
      label: 'Step Therapy',
      items: [
        {
          value: 'csornpsurgery',
          text: 'Systemic corticosteriod therapy OR one or more prior nasal surgeries'
        },
        {
          value: 'xhance',
          text: 'Systemic corticosteriod therapy OR one or more prior nasal surgeries AND Xchance'
        },
        {
          value: 'ctandpsurgery',
          text: 'Systemic corticosteriod therapy AND one or more prior nasal surgeries'
        },
        {
          value: 'na',
          text: 'Not Applicable'
        }
      ]
    },
    {
      code: 'relative_coverage',
      label: 'Relative Coverage',
      items: [
        {
          value: 'all_parity',
          text: 'All products parity'
        },
        {
          value: '1_of_4',
          text: 'Preferred 1 of 4 w/bio step'
        },
        {
          value: '1_of_3',
          text: 'Preferred 1 of 3 w/bio step'
        },
        {
          value: '1_of_2',
          text: 'Preferred 1 of 2 w/bio step'
        },
        {
          value: '1_of_1',
          text: 'Preferred 1 of 1 w/bio step'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    }
  ],
  Aubagio: [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered',
          text: 'Covered'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'prior_authorization',
      label: 'Prior Authorization',
      items: [
        {
          value: 'palessthanlabel',
          text: 'PA less restrictive than label'
        },
        {
          value: 'PA_to_label',
          text: 'PA to label'
        },
        {
          value: 'more_restrictive',
          text: 'PA more restrictive than label (e.g. documentation of failure)'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'Step_restrictions',
      label: 'Step Restrictions',
      items: [
        {
          value: 'nose',
          text: 'No step edit'
        },
        {
          value: 'sse',
          text: 'Single step edit'
        },
        {
          value: 'dse',
          text: 'Double step edit'
        },
        {
          value: 'tse',
          text: 'Triple step edit'
        },
        {
          value: 'qse_+',
          text: 'Quadruple step or more'
        },
        {
          value: 'medical',
          text: 'Medical exception'
        }
      ]
    },
    {
      code: 'relative_coverage',
      label: 'Relative Coverage',
      items: [
        {
          value: 'advg_orals_advg_dmf',
          text: 'Advantaged to Branded Orals; Advantaged to Generic DMF'
        },
        {
          value: 'advg_orals_pty_dmf',
          text: 'Advantaged to Branded Orals; Parity to Generic DMF'
        },
        {
          value: 'advg_orals_dto_dmf',
          text: 'Advantaged to Branded Orals; Disadvantaged to Generic DMF'
        },
        {
          value: 'pty_orals_advg_dmf',
          text: 'Parity to Branded Orals; Advantaged to Generic DMF'
        },
        {
          value: 'pty_orals_pty_dmf',
          text: 'Parity to Branded Orals; Parity to Generic DMF'
        },
        {
          value: 'pty_orals_dto_dmf',
          text: 'Parity to Branded Orals; Disadvantaged to Generic DMF'
        },
        {
          value: 'dto_orals_advg_dmf',
          text: 'Disadvantaged to Branded Orals; Advantaged to Generic DMF'
        },
        {
          value: 'dto_orals_pty_dmf',
          text: 'Disadvantaged to Branded Orals; Parity to Generic DMF'
        },
        {
          value: 'dto_orals_dto_dmf',
          text: 'Disadvantaged to Branded Orals; Disadvantaged to Generic DMF'
        }
      ]
    }
  ],
  Lemtrada: [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered_less',
          text: 'Covered - less restrictive than label'
        },
        {
          value: 'covered_to',
          text: 'Covered to Label'
        },
        {
          value: 'covered_more',
          text: 'Covered - more restrictive than label'
        }
      ]
    },
    {
      code: 'prior_authorization',
      label: 'Prior Authorization',
      items: [
        {
          value: 'palessthanlabel',
          text: 'PA less restrictive than label'
        },
        {
          value: 'PA_to_label',
          text: 'PA to label'
        },
        {
          value: 'more_restrictive',
          text: 'PA more restrictive than label (e.g. documentation of failure)'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'Step_restrictions',
      label: 'Step Restrictions',
      items: [
        {
          value: 'nose',
          text: 'No step edit'
        },
        {
          value: 'sse',
          text: 'Single step edit'
        },
        {
          value: 'dse',
          text: 'Double step edit'
        },
        {
          value: 'tse',
          text: 'Triple step edit'
        },
        {
          value: 'qse_+',
          text: 'Quadruple step edit or more'
        },
        {
          value: 'medical',
          text: 'Medical exception'
        }
      ]
    }
  ],
  Kevzara: [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered',
          text: 'Covered'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'prior_authorization',
      label: 'Prior Authorization',
      items: [
        {
          value: 'palessthanlabel',
          text: 'PA less restrictive than label'
        },
        {
          value: 'basic_betch_PA',
          text: 'Basic PA'
        },
        {
          value: 'more_restrictive',
          text: 'PA more restrictive than label (e.g. documentation of failure)'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'Step_restrictions',
      label: 'Step Restrictions',
      items: [
        {
          value: 'nose',
          text: 'No step edit'
        },
        {
          value: 'choice',
          text:
            // tslint:disable-next-line: max-line-length
            'Agent of choice after TNF or JAK (forced step prior to Actemra or Orencia, could be SSE or DSE)'
        },
        {
          value: 'dse_preferred',
          text: 'DSE after TNF or JAK and preferred over Actemra'
        },
        {
          value: 'dse_nonpreferred',
          text: 'DSE after TNF or JAK and parity with Actemra'
        },
        {
          value: 'dse_plus_and_disadvantaged',
          text: 'DSE after TNF or JAK and disadvantaged to Actemra'
        }
      ]
    },
    {
      code: 'relative_coverage',
      label: 'Relative Coverage',
      items: [
        {
          value: 'preferred',
          text: 'Kevzara is preferred to Actemra'
        },
        {
          value: 'Parity',
          text: 'Kevzara is at parity with Actemra'
        },
        {
          value: 'disadvantaged',
          text: 'Kevzara is disadvantaged to Actemra'
        }
      ]
    }
  ],
  Fabrazyme: [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered',
          text: 'Covered'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'prior_authorization',
      label: 'Prior Authorization',
      items: [
        {
          value: 'no_pa',
          text: 'No PA'
        },
        {
          value: 'pa_to_label',
          text: 'PA to label'
        },
        {
          value: 'more_restrictive',
          text: 'PA more restrictive than label'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'relative_coverage',
      label: 'Relative Coverage',
      items: [
        {
          value: 'advantaged',
          text: 'Advantaged to Galafold'
        },
        {
          value: 'parity',
          text: 'Parity with Galafold'
        },
        {
          value: 'disadvantaged',
          text: 'Disadvantaged to Galafold'
        }
      ]
    }
  ],
  Cerezyme: [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered',
          text: 'Covered'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'prior_authorization',
      label: 'Prior Authorization',
      items: [
        {
          value: 'no_pa',
          text: 'No PA'
        },
        {
          value: 'pa_to_label',
          text: 'PA to label'
        },
        {
          value: 'more_restrictive',
          text: 'PA more restrictive than label'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'relative_coverage',
      label: 'Relative Coverage',
      items: [
        {
          value: 'adv_adv',
          text: 'Advantaged to ELELYSO, Advantaged to VPRIV'
        },
        {
          value: 'adv_parity',
          text: 'Advantaged to ELELYSO, Parity to VPRIV'
        },
        {
          value: 'adv_disadv',
          text: 'Advantaged to ELELYSO, Disadvantaged to VPRIV'
        },
        {
          value: 'parity_adv',
          text: 'Parity to ELELYSO, Advantaged to VPRIV'
        },
        {
          value: 'parity_parity',
          text: 'Parity to ELELYSO, Parity to VPRIV'
        },
        {
          value: 'parity_disadv',
          text: 'Parity to ELELYSO, Disadvantaged to VPRIV'
        },
        {
          value: 'disadv_adv',
          text: 'Disadvantaged to ELELYSO, Advantaged to VPRIV'
        },
        {
          value: 'disadv_parity',
          text: 'Disadvantaged to ELELYSO, Parity to VPRIV'
        },
        {
          value: 'disadv_disadv',
          text: 'Disadvantaged to ELELYSO, Disadvantaged to VPRIV'
        }
      ]
    }
  ],
  Cerdelga: [
    {
      code: 'coverage',
      label: 'Coverage',
      items: [
        {
          value: 'covered',
          text: 'Covered'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'prior_authorization',
      label: 'Prior Authorization',
      items: [
        {
          value: 'no_pa',
          text: 'No PA'
        },
        {
          value: 'pa_to_label',
          text: 'PA to label'
        },
        {
          value: 'more_restrictive',
          text: 'PA more restrictive than label'
        },
        {
          value: 'medical_exception',
          text: 'Medical Exception'
        }
      ]
    },
    {
      code: 'relative_coverage',
      label: 'Relative Coverage',
      items: [
        {
          value: 'adv_adv',
          text: 'Advantaged to ELELYSO, Advantaged to VPRIV'
        },
        {
          value: 'adv_parity',
          text: 'Advantaged to ELELYSO, Parity to VPRIV'
        },
        {
          value: 'adv_disadv',
          text: 'Advantaged to ELELYSO, Disadvantaged to VPRIV'
        },
        {
          value: 'parity_adv',
          text: 'Parity to ELELYSO, Advantaged to VPRIV'
        },
        {
          value: 'parity_parity',
          text: 'Parity to ELELYSO, Parity to VPRIV'
        },
        {
          value: 'parity_disadv',
          text: 'Parity to ELELYSO, Disadvantaged to VPRIV'
        },
        {
          value: 'disadv_adv',
          text: 'Disadvantaged to ELELYSO, Advantaged to VPRIV'
        },
        {
          value: 'disadv_parity',
          text: 'Disadvantaged to ELELYSO, Parity to VPRIV'
        },
        {
          value: 'disadv_disadv',
          text: 'Disadvantaged to ELELYSO, Disadvantaged to VPRIV'
        }
      ]
    }
  ]
};
