export enum RenderFormType {
  ACCOUNT_ASSESSMENT = 'account-assessment',
  ACCOUNT_CONSIDERATIONS = 'account-considerations',
  ACCOUNT_STRATEGY = 'account-strategy',
  BRAND_ASSESSMENT = 'brand-assessment',
  BRAND_STRATEGY = 'brand-strategy',
  BELIEF_MATRIX = 'belief-matrix',
  CUSTOM_ACCOUNT_STRATEGY = 'custom-account-strategy',
  CUSTOM_BRAND_STRATEGY = 'custom-brand-strategy',
  DEGREE_OF_RELATIONSHIP = 'degree-of-relationship',
  GOALS = 'goals-formulary',
  GOALS_AND_OBJECTIVES_BRAND = 'goals-and-objectives-brand',
  GOALS_AND_OBJECTIVES_FORM = 'goals-and-objectives-formulary',
  INFLUENCER_ASSESSMENT = 'influencer-assessment'
}

export enum RenderComponentType {
  DEFAULT = 'default',
  TEXT = 'text',
  MULTI_TEXT = 'multi-text',
  TEXT_MULTILINE = 'text-multiline',
  MULTI_TEXT_MULTILINE = 'multi-text-multiline',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  DROPDOWN = 'dropdown',
  SLIDER = 'slider',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  NUMBER = 'number',
  PERCENT = 'percent',
  MILESTONES = 'milestones',
  RADIO_WITH_MONTH = 'radio-with-month',
  NUMBER_OR_UNKNOWN = 'number-or-unknown',
  MULTIPLE_SELECT = 'multiple-select',
  SEGMENTATION = 'segmentation'
}

export enum RenderComponentOptionType {
  DEFAULT = 'default',
  TEXT = 'text',
  MONTH = 'month',
  NUMBER = 'number',
}
