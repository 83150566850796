import { isNumeric } from './validation.helper';

export const COPY_NAME_REGEX = /^Copy\[([0-9]+)] of (.*)$/;

export const getNextCopyTitle = (originalTargetTitle: string, titles: string[]): string => {
  const targetTitle = originalTargetTitle.trim();
  const copyNumbers: number[] = [];
  const hasCopyName = COPY_NAME_REGEX.test(targetTitle);

  let originalTitle = targetTitle;
  if (hasCopyName) {
    originalTitle = extractCopyNameTitle(targetTitle);
  }

  const allTitles = titles;
  if (!titles.includes(targetTitle)) {
    allTitles.push(targetTitle);
  }

  for (const title of allTitles) {
    if (RegExp(`of ${originalTitle}$`).test(title) && title.includes('Copy')) {
      copyNumbers.push(getNumberOfCopyTitle(title));
    }
  }

  const nextCopyNumber = copyNumbers.length > 0 ? Math.max(...copyNumbers) + 1 : 1;

  return `Copy[${nextCopyNumber}] of ${originalTitle}`;
};

export const getNumberOfCopyTitle = (name: string): number => {
  const parts = name.match(COPY_NAME_REGEX);
  if (parts && parts.length > 0 && isNumeric(parts[1])) {
    const num = parseInt(parts[1], 0);
    if (num > 0) {
      return num;
    }
  }
  return 0;
};

export const extractCopyNameTitle = (name: string): string => {
  const parts = name.match(COPY_NAME_REGEX);
  if (parts && parts.length > 0 && parts[2]) {
    return parts[2];
  }
  return '';
};
