export enum DatetimeFormat {
  prettifiedCommas = 'MMMM Do, YYYY [at] hh:mma',
  prettifiedSlashes = 'MM/DD/YYYY [at] hh:mma',
  prettifiedSlashesWithHyphen = 'MM/DD/YYYY - HH:mm',
  prettifiedDayOfMonth = 'MMMM D, YYYY',
  partSlashes = 'MM/DD/YY',
  fullSlashes = 'MM/DD/YYYY',
  fullSlashesOneDigitMonth = 'M/DD/YYYY',
  fullHyphensFullDateTime = 'MM-DD-YYYY HH:mm:ss ZZ',
  fullHyphensDateTime = 'YYYY-MM-DDTHH:mm:ss',
  fullHyphensYearMonthDay = 'YYYY-MM-DD',
  fullHyphensDayMonthYear = 'DD-MM-YYYY',
  fullHyphensMonthDayYear = 'MM-DD-YYYY',
  yearMonthHyphensReversed = 'YYYY-MM',
  monthYearSpace = 'MMMM YYYY',
  fullQuarter = '[Q]Q YYYY',
  singleQuarter = 'Q YYYY'
}

export interface DatetimeFormatReverseMap {
  [value: string]: string; // key of DatetimeFormat enum
}
