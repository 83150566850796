import { useEffect, useState } from 'react';
import { throttle as lodashThrottle } from 'lodash';

export const useWindowScrollPosition = () => {
  const getPosition = () => ({
    x: window.pageXOffset,
    y: window.pageYOffset
  });

  const throttle = 100;
  const [position, setPosition] = useState<any>(getPosition());

  useEffect(() => {
    const handleScroll = lodashThrottle(() => {
      setPosition(getPosition());
    }, throttle);

    window.addEventListener(
      'scroll',
      handleScroll,
      false
    );

    return () => {
      handleScroll.cancel();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return position;
};
