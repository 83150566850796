import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

import modalStyles from '../../../../styles/modal.module.scss';
import { IUnsuccessfulRequestModalProps } from './UnsuccessfulRequestModal.types';
import { DownloadErrorMessage } from './download-error-message/DownloadErrorMessage';

export const UnsuccessfulRequestModal: React.FC<IUnsuccessfulRequestModalProps> = ({
  isOpened,
  onCancel,
  onTryAgain,
  onContinueDownload,
  errorMessage,
  modalText,
  showScreenshotMessage,
  allowTryAgain
}) => {
  return (
    <Modal
      className={modalStyles.deleteModal}
      open={isOpened}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      size="tiny"
      dimmer="blurring"
      onClose={onCancel}
      closeIcon
    >
      <Modal.Header className={modalStyles.deleteModalHeader}>Download Error</Modal.Header>

      <Modal.Content>
        <div className={modalStyles.deleteModalContent}>
          <p>
            <strong>{modalText}</strong>
          </p>
          {errorMessage && <DownloadErrorMessage errorMessage={errorMessage} showScreenshotMessage={showScreenshotMessage}/>}
        </div>
      </Modal.Content>

      <Modal.Actions className={modalStyles.deleteModalActions}>
        {
          allowTryAgain
            ? <>
              <Button primary onClick={onTryAgain}>
                {'Try Again'}
              </Button>
              {onContinueDownload
                ? <Button negative onClick={onContinueDownload}>
                  {'Continue with Download'}
                </Button>
                : <Button onClick={onCancel}>
                  {'Close'}
                </Button>}
            </>
            : <Button primary onClick={onCancel}>
              {'Close'}
            </Button>
        }
      </Modal.Actions>
    </Modal>
  );
};
