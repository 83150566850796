export const knownRoleIds = {
  VP: 'VP',
  VNA: 'V&A',
  DR: 'DR',
  AE: 'AE',
  CBE: 'CBE',
  SDR: 'SDR',
  PLANNING: 'PLANNING',
  PROFILING: 'PROFILING',
  COMMUNICATING: 'COMMUNICATING',
  VAL: 'VAL',
  SUPERADMIN: 'SUPERADMIN',
  ADMIN: 'ADMIN',
  EVP: 'EVP'
};
