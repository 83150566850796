export enum StatusEnum {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED'
}

export enum DatabaseEntityStatus {
  active = 'active',
  deleted = 'deleted'
}
