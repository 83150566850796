import React from 'react';
import { SemanticToastContainer } from 'react-semantic-toasts';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { RecoilRoot } from 'recoil';

import 'react-semantic-toasts/styles/react-semantic-alert.css';

import { RootContextComponent } from '../contexts/RootContext';
import { Routes } from './_routing/routes';
import styles from './App.module.scss';
import { appInsightsReactPlugin, getWithAppInsights } from '@amp/app-insights';
import { ConfirmProvider } from './common/confirm/Confirm.context';
import { Confirm } from './common/confirm/Confirm';
import { UsageReportModal } from '@amp/components/common/modals/usage-report-modal/UsageReportModal';

class AppComponent extends React.Component {
  render () {
    return (
      <AppInsightsContext.Provider value={appInsightsReactPlugin}>
        <RecoilRoot>
          <RootContextComponent>
            <ConfirmProvider component={Confirm}>
              <UsageReportModal />
              <div className={styles.app}>
                <Routes />
                <SemanticToastContainer />
              </div>
            </ConfirmProvider>
          </RootContextComponent>
        </RecoilRoot>
      </AppInsightsContext.Provider>
    );
  }
}

const WithAppInsights = getWithAppInsights();
export const App = WithAppInsights(AppComponent);
