import React from 'react';
import * as Survey from 'survey-react';
import * as SurveyKO from 'survey-knockout';
import { QuestionDatepicker, QuestionDatepickerModel, SURVEY_DATEPICKER_QUESTION_TYPE } from './SurveyQuestionDatepicker';

export const registerSurveyQuestionDatepicker = (): void => {
  const widget = {
    name: SURVEY_DATEPICKER_QUESTION_TYPE,
    title: 'Date Picker',
    iconName: '',
    isDefaultRender: false,
    htmlTemplate: '<div><input type="date"></div>',
    widgetIsLoaded: () => true,
    isFit: (question: SurveyKO.Question) => question.getType() === SURVEY_DATEPICKER_QUESTION_TYPE,
    init: () => SurveyKO.Serializer.addClass(SURVEY_DATEPICKER_QUESTION_TYPE, [], undefined, 'empty')
  };
  SurveyKO.CustomWidgetCollection.Instance.add(widget, 'customtype');

  Survey.Serializer.addClass(SURVEY_DATEPICKER_QUESTION_TYPE, [], () => new QuestionDatepickerModel(''), 'textbase');
  Survey.QuestionFactory.Instance.registerQuestion(SURVEY_DATEPICKER_QUESTION_TYPE, (name: string) => new QuestionDatepickerModel(name));
  Survey.ReactQuestionFactory.Instance.registerQuestion(
    SURVEY_DATEPICKER_QUESTION_TYPE,
    (props: any) => React.createElement(QuestionDatepicker, props)
  );
};
