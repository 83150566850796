import React, { useContext } from 'react';
import { IDownloadItemProps } from './DownloadItem.types';

import styles from './DownloadItem.module.scss';
import { useDownloadItem } from './DownloadItem.hooks';
import { Icon } from 'semantic-ui-react';
import { truncateText } from '@amp/helpers/textHelpers';
import { RootContext } from '@amp/contexts/RootContext';
import { DivButton } from '@amp/components/common/buttons/div-button/DivButton';
import { downloadResource } from '@amp/helpers/download';

export const DownloadItem: React.FC<IDownloadItemProps> = ({ item }) => {
  const { isOnline } = useContext(RootContext);
  const {
    deleteDownloadItem,
    showReportFailedMessage,
    timeToExpire
  } = useDownloadItem({ item });

  return <div className={styles.item}>
    <div className={styles.reportInfo}>
      <div className={styles.spacer}/>
      <div className={styles.reportTitle}>{truncateText(item.reportTitle || '', 40)}</div>
      {item.status === 'Finished' && <div className={styles.reportExpiration}>Download will be deleted in {timeToExpire}</div>}
      <div className={styles.spacer}/>
    </div>
    <div className={styles.reportStatusContainer}>
      <div className={styles.spacer}/>
      {['Starting', 'In Progress'].includes(item.status) && <div className={styles.reportStatusInProgress}>{item.status.toUpperCase()}...</div>}
      {item.status === 'Failed' && <DivButton
        className={styles.reportStatusError}
        onClick={() => {
          showReportFailedMessage(item.id);
        }}
      >
        {item.status.toUpperCase()}
      </DivButton>}
      {item.status === 'Finished' && <div className={styles.reportStatusDownload}>
        {isOnline
          ? <button onClick={() => downloadResource(item.url!, item.reportFile!)} >
            DOWNLOAD <Icon name={'download'} className={styles.downloadIcon}/>
          </button >
          : <span className={styles.downloadDisabled}>
            DOWNLOAD <Icon name={'download'} className={styles.downloadIcon}/>
          </span>
        }
      </div>}
      <div className={styles.spacer}/>
    </div>
    <div className={styles.actions}>
      <div className={styles.spacer}/>
      {isOnline
        ? <Icon name={'trash alternate outline'} className={styles.deleteAction} onClick={deleteDownloadItem}/>
        : <Icon name={'trash alternate outline'} className={styles.deleteActionDisabled}/>
      }

      <div className={styles.spacer}/>
    </div>
  </div>;
};
