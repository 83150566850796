import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { config } from '@amp/config/config';

const browserHistory = createBrowserHistory({ basename: '' });
export const appInsightsReactPlugin = new ReactPlugin();

let appInsightsClient: ApplicationInsights | undefined;

export const initAppInsights = (): void => {
  if (config.appInsightsKey) {
    appInsightsClient = new ApplicationInsights({
      config: {
        endpointUrl: config.appInsightsUrl,
        enableAutoRouteTracking: true,
        enableSessionStorageBuffer: true,
        isStorageUseDisabled: false,
        instrumentationKey: config.appInsightsKey,
        extensions: [appInsightsReactPlugin],
        extensionConfig: {
          [appInsightsReactPlugin.identifier]: { history: browserHistory }
        }
      }
    });
  }
};

export const getAppInsightsClient = (): ApplicationInsights | undefined => appInsightsClient;
export const getWithAppInsights = () => (Component: React.ComponentType): React.ComponentClass | React.ComponentType => {
  if (appInsightsClient) {
    return withAITracking(appInsightsReactPlugin, Component);
  }
  return Component;
};
