const resourcesConfig = {
  dashboardPlanningCard: { type: 'pageElement' },
  planningPages: { type: 'urlPattern', pattern: /[/]planning/ },

  dashboardProfilingCard: { type: 'pageElement' },
  profilingPages: { type: 'urlPattern', pattern: /[/]profiling/ },
  lvOverviewSection: { type: 'pageElement' },

  dashboardCommunicatingCard: { type: 'pageElement' },
  communicatingAdminMenuLink: { type: 'pageElement' },
  communicatingPages: { type: 'urlPattern', pattern: /[/]dva/ },

  dashboardEventTrackerCard: { type: 'pageElement' },
  eventTrackerPages: { type: 'urlPattern', pattern: /[/]event-tracker/ },

  homePage: { type: 'urlPattern', pattern: /.*[/]home/ },
  adminPages: { type: 'urlPattern', pattern: /.*admin.*/ },

  loginPage: { type: 'urlPattern', pattern: /^[/]login$/ },
  resetPassword: { type: 'urlPattern', pattern: /^[/]reset-password$/ },
  changePassword: { type: 'urlPattern', pattern: /^[/]change-password$/ },

  manageFeaturesMenuItem: { type: 'pageElement' },
  manageFeaturesPages: { type: 'urlPattern', pattern: /^[/]manage[-]features$/ },

  influencerPageElement: { type: 'pageElement' },

  accountConsiderationsPageElements: { type: 'pageElement' },
  accountAssessmentPageElements: { type: 'pageElement' },
  brandAssessmentPageElements: { type: 'pageElement' },
  goalsAndObjectivesPageElements: { type: 'pageElement' },
  customStrategiesPageElements: { type: 'pageElement' },
  customAccountStrategiesPageElements: { type: 'pageElement' },
  customBrandStrategiesPageElements: { type: 'pageElement' },
  accountStrategiesPageElements: { type: 'pageElement' },
  strategiesAndTacticsMenuItem: { type: 'pageElement' },
  canUseNotificationsSystem: { type: 'pageElement' },

  degreeOfRelationshipPageElements: { type: 'pageElement' },
  beliefMatricesPageElements: { type: 'pageElement' },
  brandStrategiesPageElements: { type: 'pageElement' },
  canUseReportsV2: { type: 'pageElement' }
} as const;

type PageElementConfig = {
  type: 'pageElement'
}

type UrlConfig = {
  type: 'urlPattern',
  pattern: RegExp
}

type ResourceConfig = PageElementConfig | UrlConfig;

type ResourcesConfig = {
  [resourceName: string]: ResourceConfig
}

export const resources: ResourcesConfig = resourcesConfig;
export type Resource = keyof typeof resourcesConfig;
