import React from 'react';
import styles from './HeaderButton.module.scss';
import { IProps } from './HeaderButton.types';

export const HeaderButton: React.FC<IProps> = props => {
  const { onClick, children } = props;

  return (
    <button
      className={`${styles.headerButton}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
