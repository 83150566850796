import { Resource } from './resources.config';

export const permissionsConfig = {
  canAccessPlanning: ['dashboardPlanningCard', 'planningPages'] as Resource[],
  canAccessProfiling: ['dashboardProfilingCard', 'profilingPages'] as Resource[],
  canAccessCommunicating: ['dashboardCommunicatingCard', 'communicatingPages', 'communicatingAdminMenuLink'] as Resource[],
  canAccessEventTracker: ['dashboardEventTrackerCard', 'eventTrackerPages'] as Resource[],
  canAccessHomePage: ['homePage'] as Resource[],
  canAccessAdminPages: ['adminPages'] as Resource[],
  canAccessPublicPages: ['loginPage', 'resetPassword', 'changePassword'] as Resource[],
  GetLeadershipViewFilters: ['lvOverviewSection'] as Resource[],
  canAccessSuperAdminPages: ['manageFeaturesMenuItem', 'manageFeaturesPages'] as Resource[],
  canAccessAccountConsiderations: ['accountConsiderationsPageElements'] as Resource[],
  canAccessAccountAssessment: ['accountAssessmentPageElements'] as Resource[],
  canAccessBrandAssessment: ['brandAssessmentPageElements'] as Resource[],
  canAccessGoalsAndObjectives: ['goalsAndObjectivesPageElements'] as Resource[],
  canAccessCustomAccountStrategies: [
    'customStrategiesPageElements',
    'strategiesAndTacticsMenuItem',
    'customAccountStrategiesPageElements'] as Resource[],
  canAccessCustomBrandStrategies: ['customStrategiesPageElements', 'strategiesAndTacticsMenuItem', 'customBrandStrategiesPageElements'] as Resource[],
  canAccessDegreeOfRelationship: ['degreeOfRelationshipPageElements', 'strategiesAndTacticsMenuItem'] as Resource[],

  canAccessInfluencer: ['influencerPageElement'] as Resource[],
  canAccessBeliefMatrices: ['beliefMatricesPageElements'] as Resource[],
  canAccessBrandStrategies: ['brandStrategiesPageElements', 'strategiesAndTacticsMenuItem'] as Resource[],
  canAccessAccountStrategies: ['accountStrategiesPageElements', 'strategiesAndTacticsMenuItem'] as Resource[],
  canUseNotificationsSystem: ['canUseNotificationsSystem'] as Resource[],
  canUseReportsV2: ['canUseReportsV2'] as Resource[]
} as const;

export const restrictedPermissionsConfig = {
  PLANNING: permissionsConfig.canAccessPlanning,
  PROFILING: permissionsConfig.canAccessProfiling,
  COMMUNICATING: permissionsConfig.canAccessCommunicating,
  EVENT_TRACKER: permissionsConfig.canAccessEventTracker
} as const;

type PermissionsConfig = {
  [permissionId: string]: Resource[]
}

export const permissions: PermissionsConfig = permissionsConfig;
export type Permission = keyof typeof permissionsConfig;
export const allPermissions: Permission[] = Object.keys(permissionsConfig) as Permission[];
