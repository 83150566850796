import { find, isEmpty, reduce } from 'lodash';
import { IQuestionToAnswer, ISTQuestion, Condition } from '../types/format.types';

export const addQuestionsToExistingForm = (
  originalForm: IQuestionToAnswer,
  newQuestions: ISTQuestion[]
): void => {
  if (originalForm.questions) {
    originalForm.questions.push(...newQuestions);
  }
};

export const getFilteredQuestions = (
  excludedQuestionTitles: string[],
  originalQuestions: ISTQuestion[]
): ISTQuestion[] => {
  if (isEmpty(excludedQuestionTitles)) {
    return originalQuestions;
  }
  let count = 0;
  return reduce(originalQuestions, (result: ISTQuestion[], question: ISTQuestion) => {
    const foundExcludedQuestion = excludedQuestionTitles.find(title => question.title === title);
    if (!foundExcludedQuestion) {
      count++;
      result.push({
        ...question,
        id: count
      });
    }
    return result;
  }, []);
};

export const updateAndAddQuestionsToExistingForm = (
  originalForm: IQuestionToAnswer,
  updatedQuestions: ISTQuestion[],
  newQuestions: ISTQuestion[]
): void => {
  updateExistingQuestionsOnExistingForm(originalForm, updatedQuestions);
  addQuestionsToExistingForm(originalForm, newQuestions);
};

export const updateExistingQuestionsOnExistingForm = (
  originalForm: IQuestionToAnswer,
  updatedQuestions: ISTQuestion[]
): void => {
  if (originalForm.questions) {
    originalForm.questions.forEach(question => {
      const foundUpdatedQuestion = find(updatedQuestions, el => question.code === el.code);
      if (foundUpdatedQuestion) {
        question = foundUpdatedQuestion;
      }
    });
  }
};

export const getNumberOfExistingQuestions = (originalForm: IQuestionToAnswer): number => {
  return originalForm.questions ? originalForm.questions.length : 0;
};

export const evaluateCondition = (
  sourceObject: any,
  objectWithReplacementText: any,
  condition: Condition
): boolean => {
  switch (condition.op) {
  case 'eq':
    if (Array.isArray(condition.value)) {
      return condition.value.includes(getSubFieldFrom(sourceObject, objectWithReplacementText, condition.code));
    }
    return getSubFieldFrom(sourceObject, objectWithReplacementText, condition.code) === condition.value;
  case 'set':
    return getSubFieldFrom(sourceObject, objectWithReplacementText, condition.code) != null;
  default:
    return true;
  }
};

export const getSubFieldFrom = (
  sourceObject: any | null,
  objectWithReplacementText: object,
  fieldCode: string
): unknown => {
  if (sourceObject === null) {
    fieldCode = substituteValue(sourceObject, objectWithReplacementText, fieldCode);
  }
  let subfield: any = objectWithReplacementText;
  const tags = fieldCode.split('.');
  // tslint:disable-next-line: prefer-for-of
  for (let i = 0; i < tags.length; i++) {
    if (!subfield) {
      return null;
    }
    subfield = subfield[tags[i]];
  }
  return subfield;
};

const replaceValue = (value: string, replacementValue: any, replacement: string) => {
  const replacementType = typeof replacementValue;
  if (replacementType === 'boolean') {
    value = value.replace(`"{{${replacement}}}"`, String(replacementValue));
  } else if (replacementType === 'object') {
    value = value.replace(`"{{${replacement}}}"`, JSON.stringify(replacementValue));
  } else {
    value = value.replace(`{{${replacement}}}`, String(replacementValue));
  }
  return value;
};

const substituteValue = (sourceObject: any, objectWithReplacementText: any, value: string): string => {
  const fullObject = getMergedObject(sourceObject, objectWithReplacementText);
  const regex = /{{(.*?)}}/g;
  let match;
  const replacements = [];
  do {
    match = regex.exec(value);
    if (match) {
      replacements.push(match[1]);
    }
  } while (match);
  replacements.forEach((replacement: string) => {
    let replacementValue = getSubFieldFrom(null, fullObject as object, replacement);
    if (replacementValue === null) {
      replacementValue = {};
    }
    value = replaceValue(value, replacementValue, replacement);
  });
  return value;
};

const getMergedObject = (sourceObject: any, objectWithReplacementText: object): any => {
  if (!sourceObject) {
    sourceObject = {};
  }
  sourceObject._object = objectWithReplacementText;
  return sourceObject;
};
