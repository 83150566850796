import { chain, filter, flatten, get, isEmpty, keyBy, mapValues } from 'lodash';

import { config } from '@amp/config/config';
import { BrandTypeDO } from '@amp/data-objects/BrandDO';
import { cacheRequest } from '@amp/helpers/httpHelper';
import { offlineCache } from '@amp/offline-cache/OfflineCache';
import { CacheEntity } from '@amp/offline-cache/OfflineCache.types';

import { Resource, resources as resourcesConfig } from './resources.config';
import { permissions as permissionsConfig, restrictedPermissionsConfig } from './permissions.config';

export async function refreshPermissions (): Promise<PermissionDTO[]> {
  try {
    return (await cacheRequest(config.permissionUrl, CacheEntity.permissions)) as PermissionDTO[];
  } catch (err) {
    return [];
  }
}

export function hasAccessToResource ({ brandName, ignoredBrandsResources, resource, restrictedRoleIds }: {
  brandName?: string,
  ignoredBrandsResources?: {[brandName: string]: string[]},
  resource?: Resource,
  restrictedRoleIds?: string[],
}): boolean {
  if (!resource) {
    return false;
  }
  if (isResourceRestricted(resource, restrictedRoleIds)) {
    return false;
  }
  if (brandName && ignoredBrandsResources) {
    const ignoreBrandResources = ignoredBrandsResources[brandName];
    if (ignoreBrandResources?.includes(resource)) {
      return false;
    }
  }

  const resources = getAllResources();
  return resources.includes(resource);
}

const isResourceRestricted = (resource: Resource, restrictedRoleIds: string[] = []): boolean => {
  if (isEmpty(restrictedRoleIds)) {
    return false;
  }
  for (const roleId of restrictedRoleIds) {
    const restrictedResources: Resource[] = get(restrictedPermissionsConfig, roleId);
    if (restrictedResources?.includes(resource)) {
      return true;
    }
  }
  return false;
};

export function hasAccessToPath (path: string): boolean {
  const urlPatterns = getAllUrlPatterns();

  const resourcePatternMatchesPath = (resource:Resource) => {
    const resourceConfig = get(resourcesConfig, resource);

    if (resourceConfig.type !== 'urlPattern') {
      return false;
    }
    return resourceConfig.pattern.test(path);
  };

  return urlPatterns
    .some(resourcePatternMatchesPath);
}

type PermissionDTO = { id: string, type: string, description: string }

export function getPermissions (): PermissionDTO[] {
  return offlineCache.cacheQueryArray(CacheEntity.permissions) || [];
}

export function getAllResources (): Resource[] {
  const permissions = getPermissions();
  return flatten(permissions.map(permission => get(permissionsConfig, permission.id, [])));
}

export function getAllUrlPatterns (): Resource[] {
  const resources = getAllResources();
  return resources.filter(resource => resourcesConfig[resource].type === 'urlPattern');
}

export const getBrandsIgnoredResources = (brandTypes: BrandTypeDO[]): {[brandName: string]: string[]} => {
  const brandTypesWithFeatureRestrictions = brandTypes.filter(bt => !isEmpty(bt.featureBrandTypes));
  const brandTypesByBrandName = keyBy(brandTypesWithFeatureRestrictions, 'name');
  return mapValues(brandTypesByBrandName, getDeniedResourcesForBrandType);
};

const getDeniedResourcesForBrandType = (brandType: BrandTypeDO): string[] => {
  const featureBrandTypes = (filter(brandType.featureBrandTypes, { enabled: false }) || []);
  const featurePermissions = flatten(featureBrandTypes.map(fbt => fbt.feature.featurePermissions));
  const deniedPermissions = featurePermissions.map(fp => fp.permission.id);
  return getDeniedResources(deniedPermissions);
};

const getDeniedResources = (deniedPermissions: string[]): string[] => {
  const deniedResources = chain(deniedPermissions)
    .map(permission => permissionsConfig[permission])
    .flatten()
    .uniq()
    .value();
  const allPermissions = Object.keys(permissionsConfig);
  const allowedPermissions = allPermissions.filter(permission => !deniedPermissions.includes(permission));
  const allowedResources = chain(allowedPermissions)
    .map(permission => permissionsConfig[permission])
    .flatten()
    .uniq()
    .value();
  return deniedResources.filter(resource => !allowedResources.includes(resource));
};
