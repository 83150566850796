import { find, filter, groupBy, mapValues, map, uniqBy } from 'lodash';

import { ISTQuestion, IAnswer, ISTRuleSet } from '../types/format.types';
import { IInfluencerDO, ISTBasedOnInfluencerAnswersModel } from '../types/influencer.types';
import { getPropertyFromStringDotNotation } from './object.helper';
import { shouldFilterStrategyWithDataFilterRules } from './form.helper';
export interface StrategyCodeToInfluencers {
  [strategyCode: string]: IInfluencerDO[];
}

type StrategyWithInfluencer = {
  strategyCode: string,
  influencer: IInfluencerDO
}

export class StrategyCalculator {
  config: ISTBasedOnInfluencerAnswersModel;
  path: string;

  constructor (config: ISTBasedOnInfluencerAnswersModel, dataPath: string) {
    this.config = config;
    this.path = dataPath;
  }

  getQuestionsByTableCode (questions: ISTQuestion[] | undefined) : ISTQuestion[] {
    return filter(questions, (el: ISTQuestion) => el.tableCode === this.path);
  }

  getStrategies (data:any, question:any, influncer: IInfluencerDO) {
    return handleBuildStrategies({ ...this.config, data, question }, influncer);
  }

  static create (config: ISTBasedOnInfluencerAnswersModel) {
    switch (config.influencerDataField) {
    case 'data.relationship':
      return new DegreeOfRelationshipStrategyCalculator(config);
    case 'data.beliefLadder':
      return new BeliefLadderStrategyCalculator(config);
    default:
      throw Error('Correct field is not selected');
    }
  }
}

class BeliefLadderStrategyCalculator extends StrategyCalculator {
  constructor (config: ISTBasedOnInfluencerAnswersModel) {
    super(config, 'data.beliefLadder');
  }

  getInfluencerData (influencer: IInfluencerDO, question: ISTQuestion) {
    const segmentTypeId = this.config.currentSegmentTypeId;
    if (!segmentTypeId) {
      return null;
    }
    const brands: string[] = influencer?.data?.segmentTypes?.[segmentTypeId]?.brands || [];
    const foundBrandTypeIdInSegmentType = find(brands, (brandTypeId: string) => brandTypeId === this.config.currentBrandId);

    if (!foundBrandTypeIdInSegmentType) {
      return null;
    }

    const questionCode = question.code;

    return influencer?.data?.beliefLadder?.[foundBrandTypeIdInSegmentType]?.[questionCode];
  }
}

class DegreeOfRelationshipStrategyCalculator extends StrategyCalculator {
  constructor (config: ISTBasedOnInfluencerAnswersModel) {
    super(config, 'data.relationship');
  }

  getInfluencerData (influencer: IInfluencerDO) {
    return getPropertyFromStringDotNotation(influencer, this.path);
  }
}

export const groupByStrategiesWithInfluencers = (strategiesTiedToInfluencers: StrategyWithInfluencer[]): StrategyCodeToInfluencers => {
  const groupedStrategies = groupBy(strategiesTiedToInfluencers, 'strategyCode');
  return mapValues(groupedStrategies, strategiesWithInfluencers => {
    const influencers = map(strategiesWithInfluencers, 'influencer');
    return uniqBy(influencers, 'id');
  });
};

export const getManyQuestionsByTableCode = (
  questions: ISTQuestion[] | undefined,
  tableCodeToMatch: string
): ISTQuestion[] => filter(questions, (el: ISTQuestion) => el.tableCode === tableCodeToMatch);

const handleBuildStrategies = (
  config: ISTBasedOnInfluencerAnswersModel,
  influencer: IInfluencerDO
): (ISTRuleSet | null) => {
  const { question, data, currentBrandId, form } = config;

  if (!data || !question) {
    return null;
  }

  const foundAnswer = getOneAnswerByCode(question.answers as IAnswer[], data);
  const foundRuleSet = getOneRuleSetByQuestionAndAnswerId(form.ruleSets, question, foundAnswer);

  if (!foundRuleSet) {
    return null;
  }

  const shouldSkipStrategy = shouldFilterStrategyWithDataFilterRules(foundRuleSet, influencer, currentBrandId);

  return shouldSkipStrategy ? null : foundRuleSet;
};

const getOneAnswerByCode = (
  answers: IAnswer[] | undefined,
  codeToMatch: string
): IAnswer | undefined => {
  if (!answers || !answers.length) {
    return;
  }
  return find(answers, (el: IAnswer) => el.code === codeToMatch);
};

const getOneRuleSetByQuestionAndAnswerId = (
  ruleSets: ISTRuleSet[] | undefined,
  question: ISTQuestion | undefined,
  answer: IAnswer | undefined
): ISTRuleSet | undefined => {
  if (!ruleSets || !ruleSets.length || !question || !answer) {
    return;
  }

  return find(ruleSets, (el: ISTRuleSet) => {
    return el.questionId === question.id && el.answerId === answer.id;
  });
};
