import React, { useContext } from 'react';
import { Container } from 'semantic-ui-react';

import { Header } from './header/Header';
import { NavigateToTop } from '../common/navigate-top/NavigateToTop';
import { RootContext } from '@amp/contexts/RootContext';
import psLogo from '../../assets/images/made_by_ps.svg';
import styles from './PageLayout.module.scss';
import { content } from './PageLayout.content';
import { config } from '@amp/config/config';

export const PageLayout = ({ children }: { children: React.FC }) => {
  const { appVersion, isPreview } = useContext(RootContext);

  if (isPreview) {
    return <>{children}</>;
  }

  return (
    <div className={styles.pageLayout}>
      <div className={styles.header}>
        <Header />
      </div>
      <div className={styles.container}>
        <Container>
          {children}
        </Container>
      </div>
      <NavigateToTop/>
      <div className={styles.appVersionWrapper}>
        {appVersion} {config.client && <span className={styles.client}>({config.client})</span>}
      </div>
      <img alt={content.madeByPSImgAlt} className={styles.madeByPS} src={psLogo} data-cy="made-by-logo"/>
    </div>
  );
};
