import { questionBank } from '../__mocks__/ViewType.data';
import { ViewType } from '../types/formview.types';
import { ISTQuestion } from '../types/format.types';

export const mockGetQuestion = (viewType:ViewType, override:Partial<ISTQuestion> = {}):ISTQuestion => {
  if (!(viewType in questionBank)) {
    throw new Error('ERROR: Unsupported viewType. Please add data for this viewType to the mock: ViewType.data.ts');
  }

  return { ...questionBank[viewType], ...override } as ISTQuestion;
};
