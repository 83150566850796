export const knownFeatureIds = {
  INFLUENCER: 'influencer',
  DEGREE_OF_RELATIONSHIP: 'degreeOfRelationship',
  BELIEF_MATRICES: 'beliefMatrices',
  BRAND_STRATEGIES: 'brandStrategies',
  ACCOUNT_STRATEGIES: 'accountStrategies',
  CUSTOM_STRATEGIES: 'customStrategies',
  CUSTOM_ACCOUNT_STRATEGIES: 'customAccountStrategies',
  CUSTOM_BRAND_STRATEGIES: 'customBrandStrategies',
  ACCOUNT_ASSESSMENT: 'accountAssessment',
  ACCOUNT_CONSIDERATIONS: 'accountConsiderations',
  BRAND_ASSESSMENT: 'brandAssessment',
  GOALS_AND_OBJECTIVES: 'goalsAndObjectives',
  PLANNING: 'planning',
  COMMUNICATING: 'communicating',
  EVENT_TRACKER: 'eventTracker',
  PROFILING: 'profiling'
};
