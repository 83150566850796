import { authzHelpers } from '@amp/helpers/authzHelpers';
import { CacheEntity } from '@amp/offline-cache/OfflineCache.types';
import { offlineCache } from '@amp/offline-cache/OfflineCache';

export const cacheRequest = async (requestUrl: string, cacheEntity: CacheEntity): Promise<unknown> => {
  const axios = authzHelpers.getHttpClient();
  const response = await axios.get(requestUrl);
  if (response?.status >= 400) {
    throw new Error(`Error when fetching ${requestUrl}`);
  }
  const data = response?.data;
  return offlineCache.cacheQueryArray(cacheEntity, data) || [];
};
