import { getAppInsightsClient } from '@amp/app-insights';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

class Logger {
  appInsightsClient: ApplicationInsights | undefined;

  init () {
    this.appInsightsClient = getAppInsightsClient();
    console.log('Logger initialized');
  }

  debug (message: string, context?: unknown) {
    console.debug(message, context);
    this.appInsightsClient?.trackTrace({ message, severityLevel: SeverityLevel.Verbose });
  }

  info (message: string, context?: unknown) {
    console.log(message, context);
    this.appInsightsClient?.trackTrace({ message, severityLevel: SeverityLevel.Information });
  }

  warn (message: string, context?: unknown) {
    console.warn(message, context);
    this.appInsightsClient?.trackTrace({ message, severityLevel: SeverityLevel.Warning });
  }

  error (message: string, context?: unknown) {
    console.error(message, context);
    this.appInsightsClient?.trackTrace({ message, severityLevel: SeverityLevel.Error });
  }
}

export const logger = new Logger();
