import { useContext } from 'react';

import { IUseDownloadsPopup } from '@amp/components/page-layout/header/downloads-popup/DownloadsPopup.types';
import { DownloadReportsContext } from '@amp/contexts/DownloadReportsContext';

export const useDownloadsPopup = (): IUseDownloadsPopup => {
  const { userDownloadItems } = useContext(DownloadReportsContext);

  return { userDownloadItems };
};
