import { ApolloProvider, useQuery } from '@apollo/client';
import jsonwebtoken from 'jsonwebtoken';
import { reduce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Loader } from 'semantic-ui-react';

import { useFlashMessage } from '@amp/hooks/useFlashMessage';
import { createOrSetTabKey, getErrorHandler, setVersion } from './RootContext.helper';
import { authzHelpers, IAuthenticatedUser } from '../helpers/authzHelpers';
import { IRootContext } from './RootContext.types';
import { config } from '@amp/config/config';
import { logger } from '@amp/logger/logger';
import { getBrandsIgnoredResources, hasAccessToResource } from '@amp/permissions/permissions.helper';
import { offlineCache } from '@amp/offline-cache/OfflineCache';
import { getAppInsightsClient } from '@amp/app-insights';
import { isAmpClientConfigInitializedState } from '@amp/components/App.atoms';
import { GetBrandTypes } from '@amp/graphql/queries/BrandTypes';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { IndexedDb } from '@amp/indexed-db/IndexedDb';
import { IDB_TABLES } from '@amp/indexed-db/IndexedDb.config';
import { RoleDO } from '@amp/data-objects/RoleDO';
import { GET_RESTRICTED_ROLES } from '@amp/graphql/queries/Users';
import { updateAmpClientConfigData } from '@amp/helpers/updateAmpClientConfigDataHelper';
import { ISTQuestionWithSubquestions } from 'amp-client-config';
import { CONFIGURABLE_FORM_QUERY } from '@amp/graphql/queries/ConfigurableForm';

export const RootContext = React.createContext<IRootContext>({
  appVersion: '',
  beliefMatrixDictionary: {},
  brandTypes: [],
  handleLogin: () => undefined,
  handleLogout: () => undefined,
  ignoredBrandsResources: {},
  isAmpClientConfigInitialized: false,
  isAuthenticated: false,
  isAuthenticatedUserAdmin: () => false,
  isOnline: false,
  isPreview: false,
  isUsageReportModalOpen: false,
  refetchBrandTypes: () => undefined,
  restrictedRoleIds: [],
  setIsPreview: () => undefined,
  setIsUsageReportModalOpen: () => undefined,
  tabKey: ''
});

export const RootContextComponent: React.FC<{ children: any }> = ({ children }) => {
  const [isAmpClientConfigInitialized, setIsAmpClientConfigInitialized] = useRecoilState(isAmpClientConfigInitializedState);
  const [isUsageReportModalOpen, setIsUsageReportModalOpen] = useState(false);

  const appInsights = useAppInsightsContext();

  const [appVersion] = useState<string>(setVersion());
  const [authenticatedUser, setAuthenticatedUser] = useState<IAuthenticatedUser>();
  const [beliefMatrixDictionary, setBeliefMatrixDictionary] = useState<{[brandTypeId: string]: ISTQuestionWithSubquestions[]}>({});
  const [restrictedRoleIds, setRestrictedRoleIds] = useState<string[]>([]);
  const [isPreview, setIsPreview] = useState(false);
  const [tabKey, setTabKey] = useState<string>('');
  const [isJwtRefreshChecked, setIsJwtRefreshChecked] = useState(false);

  // @TODO AMPD-2142 Fix during ServiceWorker refactor
  // const isOnline = useNetworkConnectivity();
  const isOnline = true;

  useEffect(() => {
    const syncOfflineEvents = async (): Promise<void> => {
      const keys = await IndexedDb.getAllKeys(IDB_TABLES.OFFLINE_APP_INSIGHTS_EVENTS);
      await Promise.all(keys.map(async key => {
        const appInsightsString = await IndexedDb.getValue(IDB_TABLES.OFFLINE_APP_INSIGHTS_EVENTS, key) as string;
        const { event, customProperties } = JSON.parse(appInsightsString);
        appInsights.trackEvent(event, customProperties);
        await IndexedDb.deleteValue(IDB_TABLES.OFFLINE_APP_INSIGHTS_EVENTS, key);
      }));
    };
    if (isOnline && authenticatedUser?.userId) {
      syncOfflineEvents();
    }
  }, [appInsights, authenticatedUser, isOnline]);

  const isAuthenticated = useMemo(() => {
    return !!authenticatedUser?.userId || false;
  }, [authenticatedUser]);

  const isAuthenticatedUserAdmin = (): boolean => {
    return !!authenticatedUser && hasAccessToResource({ resource: 'adminPages' });
  };

  const initialRefreshJwtAndSetInterval = async () => {
    await authzHelpers.refreshAuthzInBackground(handleLogout, setAuthenticatedUser);
    setIsJwtRefreshChecked(true);

    const intervalId = authzHelpers.createJwtRefreshInterval(handleLogout);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    const appInsightsClient = getAppInsightsClient();

    if (appInsightsClient) {
      if (authenticatedUser) {
        appInsightsClient.setAuthenticatedUserContext(authenticatedUser.userId, config.client, true);
      } else {
        try {
          appInsightsClient.clearAuthenticatedUserContext();
        } catch (err) {}
      }

      if (!appInsightsClient.appInsights.isInitialized()) {
        appInsightsClient.loadAppInsights();
      }

      appInsightsClient.addTelemetryInitializer((envelope) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        envelope.data.client = config.client || '';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        envelope.data.appEnv = config.appEnv || '';

        return true;
      });
    }
  }, [authenticatedUser]);

  const {
    data: brandTypesQuery,
    refetch: refetchBrandTypes
  } = useQuery<{getBrandTypes: [{id: string, name: string, group: string}]}>(GetBrandTypes, {
    client: authzHelpers.apolloClient,
    notifyOnNetworkStatusChange: true
  });

  const handleLogin = (jwt: string) => {
    const newAuthenticatedUser = jsonwebtoken.decode(jwt) as IAuthenticatedUser;

    setAuthenticatedUser(newAuthenticatedUser);

    refetchBrandTypes();

    return newAuthenticatedUser;
  };

  const handleLogout = () => {
    setAuthenticatedUser(undefined);
    authzHelpers.apolloClient.cache.reset();
    authzHelpers.deleteJwtFromLocalStorage();
    offlineCache.clearDataAfterLogout();
  };

  const flashMessageFunctions = useFlashMessage();
  const errorHandler = getErrorHandler(handleLogout, flashMessageFunctions);

  useEffect(() => {
    authzHelpers.refreshAuthzInBackground(handleLogout);
  }, [isOnline]);

  useEffect(() => {
    const initialization = async () => {
      try {
        logger.init();

        initialRefreshJwtAndSetInterval();

        authzHelpers.createApolloClient(errorHandler);
        authzHelpers.addHandlerForLogoutInDifferentTab(handleLogout);
        createOrSetTabKey(tabKey, setTabKey);
      } catch (e) {
        logger.error(String(e));
      }
    };

    initialization();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const brandTypes = useMemo(() => {
    return brandTypesQuery ? brandTypesQuery.getBrandTypes : [];
  }, [brandTypesQuery]);

  useEffect(() => {
    const loadBeliefMatrixDictionary = async () => {
      const client = authzHelpers.apolloClient;
      const dictionary: {[brandTypeId: string]: ISTQuestionWithSubquestions[]} = {};
      await Promise.all(brandTypes.map(async brandType => {
        const { data } = await client.query({
          query: CONFIGURABLE_FORM_QUERY,
          variables: {
            dto: {
              brandTypeId: brandType.id,
              formType: 'belief-matrix',
              segmentTypeId: '',
              version: 1
            }
          },
          fetchPolicy: 'network-only'
        });
        dictionary[brandType.id] = data.GetTranspiledConfigurableFormQuestions;
      }));
      setBeliefMatrixDictionary(dictionary);
    };
    loadBeliefMatrixDictionary();
  }, [brandTypes]);

  const {
    data: restrictedRolesQuery
  } = useQuery<{ GetRestrictedRoles: RoleDO[] }>(GET_RESTRICTED_ROLES, {
    client: authzHelpers.apolloClient,
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    const roleIds = reduce(restrictedRolesQuery?.GetRestrictedRoles ?? [], (
      result: string[], role: RoleDO
    ) => {
      result.push(role.id);
      return result;
    }, []);
    setRestrictedRoleIds(roleIds);
  }, [restrictedRolesQuery]);

  const ignoredBrandsResources = useMemo(() => {
    return getBrandsIgnoredResources(brandTypes);
  }, [brandTypes]);

  useEffect(() => {
    if (isAmpClientConfigInitialized) {
      return;
    }

    const fetchData = async () => {
      try {
        await updateAmpClientConfigData();
        setIsAmpClientConfigInitialized(true);
      } catch (err) {
        if (isAmpClientConfigInitialized) {
          setIsAmpClientConfigInitialized(false);
        }
        console.error(err);
      }
    };

    fetchData();
  }, [authenticatedUser, isAmpClientConfigInitialized, setIsAmpClientConfigInitialized]);

  const defaultContext: IRootContext = {
    isUsageReportModalOpen,
    setIsUsageReportModalOpen,
    appVersion,
    beliefMatrixDictionary,
    brandTypes,
    refetchBrandTypes,
    ignoredBrandsResources,
    isAuthenticated,
    authenticatedUser,
    isOnline,
    isPreview,
    setIsPreview,
    isAuthenticatedUserAdmin,
    restrictedRoleIds,
    isAmpClientConfigInitialized,
    tabKey,
    handleLogout,
    handleLogin
  };

  if (isJwtRefreshChecked) {
    return (
      <RootContext.Provider value={defaultContext}>
        <ApolloProvider client={authzHelpers.apolloClient}>{children}</ApolloProvider>
      </RootContext.Provider>
    );
  }

  return <Loader active />;
};
