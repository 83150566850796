import { isEmpty } from 'lodash';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { IDownloadsPopupProps } from './DownloadsPopup.types';
import { useDownloadsPopup } from './DownloadsPopup.hooks';
import styles from './DownloadsPopup.module.scss';

import { DownloadItem } from '@amp/components/page-layout/header/downloads-popup/download-item/DownloadItem';

export const DownloadsPopup: React.FC<IDownloadsPopupProps> = ({ trigger }) => {
  const { userDownloadItems } = useDownloadsPopup();

  return <Popup
    className={styles.popup}
    trigger={trigger}
    on={['click']}
    content={
      <div className={styles.root}>
        <div className={styles.content}>
          {
            !isEmpty(userDownloadItems)
              ? userDownloadItems.map(item => (<DownloadItem key={item.id} item={item}/>))
              : <span className={styles.noContent}>No downloads avaliable.</span>
          }
        </div>
      </div>
    }
    position="bottom right"
  />;
};
