import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from '@amp/components/page-layout/PageLayout.module.scss';
import { ReactComponent as ArrowUp } from '@amp/assets/images/arrow_up.svg';
import { useWindowScrollPosition } from '@amp/hooks/useWindowScroll';
import { DivButton } from '@amp/components/common/buttons/div-button/DivButton';

export const NavigateToTop: React.FC = () => {
  const position = useWindowScrollPosition();
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  useEffect(() => {
    setIsEnabled(position.y <= 0);
  }, [position]);

  return <>
    <DivButton className={classNames(styles.toTop, isEnabled
      ? styles.disabled
      : '')} onClick={() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }}>
      <ArrowUp/>
    </DivButton></>;
};
