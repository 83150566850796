import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { UnsuccessfulRequestModal } from '@amp/components/common/modals/unsuccessful-request-modal/UnsuccessfulRequestModal';
import { logger } from '@amp/logger/logger';
import { disableAuthenticatedLinkDownloadState } from '@amp/components/dashboard/planning/Planning.atoms';

import { IAuthenticatedLinkProps } from './AuthenticatedDownloadLink.types';
import styles from './AuthenticatedDownloadLink.module.scss';

import { getErrorMessage } from '../error/error';

const MAX_UNSUCCESSFUL_REQUESTS = 2;
const DEFAULT_MESSAGE = 'Sorry, there’s a technical error and we can’t complete your download.';

export const AuthenticatedDownloadLink: React.FC<IAuthenticatedLinkProps> = props => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const { url, method, body, contentType, disabled, allowTryAgain, allowTryAgainMessage } = props;

  const [downloading, setDownloading] = useState<boolean>(false);
  const [disableAuthenticatedLinkDownload, setDisableAuthenticatedLinkDownload] = useRecoilState(disableAuthenticatedLinkDownloadState);

  const [countUnsuccessfulRequests, setCountUnsuccessfulRequests] = useState(0);
  const [showUnsuccessfulModal, setShowUnsuccessfulModal] = useState(false);
  const [unsuccessfulModalErrorMessage, setUnsuccessfulModalErrorMessage] = useState<string>();
  const [modalText, setModalText] = useState<string>();
  const [fileHref, setFileHref] = useState<string>();

  const getWarningMessage = (headers: Headers): (string | undefined) => {
    const warningString = headers.get('X-amp-warning');
    if (!warningString) { return; }
    try {
      const warningArray = JSON.parse(warningString);
      return warningArray.join('\n');
    } catch (err) {
      throw Error('Bad formatted warning message. It should be an array of strings.');
    }
  };

  const downloadResult = async (href: string) => {
    if (linkRef && linkRef.current) {
      linkRef.current.download = props.filename;
      linkRef.current.href = href;
      linkRef.current.click();
      linkRef.current.removeAttribute('href');
      linkRef.current.removeAttribute('download');
    }
    setDownloading(false);
    setDisableAuthenticatedLinkDownload(false);
  };

  const showUnsuccessfulRequestSlide = (text: string, href?: string) => {
    setModalText(text);
    setShowUnsuccessfulModal(true);
    setFileHref(href);
  };

  const closeUnsuccessfulModal = () => {
    setUnsuccessfulModalErrorMessage(undefined);
    setDownloading(false);
    setDisableAuthenticatedLinkDownload(false);
    setShowUnsuccessfulModal(false);
    setFileHref(undefined);
  };

  useEffect(() => {
    setDownloading(false);
    setDisableAuthenticatedLinkDownload(false);
  }, [url, setDisableAuthenticatedLinkDownload]);

  useEffect(() => {
    if (!downloading) { return; }

    const myAbortController = new AbortController();

    const fetchFile = async ({
      downloadUrl = url,
      isRedirect = false
    }): Promise<void> => {
      const token = localStorage.getItem('token') || '';

      if (!token) {
        setDownloading(false);
        setDisableAuthenticatedLinkDownload(false);
        return;
      }

      const options: RequestInit = {
        signal: myAbortController.signal
      };
      if (!isRedirect) {
        options.headers = {
          'content-type': contentType ||
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          authorization: `Bearer ${token}`
        };
      }
      if (method) {
        options.method = method;
      }
      if (body) {
        options.body = JSON.stringify(body);
      }
      const result = await fetch(downloadUrl, options);
      if (result.redirected) {
        return fetchFile({
          downloadUrl: result.url,
          isRedirect: true
        });
      }
      try {
        const warningMessage = getWarningMessage(result.headers);
        setUnsuccessfulModalErrorMessage(warningMessage);
      } catch (err) {
        logger.error(err ? getErrorMessage(err) : 'Unknown error');
      }

      const blob = await result.blob();
      const href = window.URL.createObjectURL(blob);

      if (result.status < 400 && result.status !== 202) {
        return downloadResult(href);
      }

      if (allowTryAgain && countUnsuccessfulRequests < MAX_UNSUCCESSFUL_REQUESTS) {
        return showUnsuccessfulRequestSlide(allowTryAgainMessage || DEFAULT_MESSAGE, href);
      }

      return showUnsuccessfulRequestSlide(DEFAULT_MESSAGE);
    };

    fetchFile({}).catch(err => {
      if ((err?.message || '') !== '') {
        setUnsuccessfulModalErrorMessage(err?.message);
      }
      showUnsuccessfulRequestSlide(DEFAULT_MESSAGE);
    });

    return () => {
      myAbortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloading, url]);

  const isFunction = typeof props.children === 'function';

  const onCancelModal = () => {
    setCountUnsuccessfulRequests(0);
    closeUnsuccessfulModal();
  };

  const onContinueDownload = async () => {
    setCountUnsuccessfulRequests(0);
    if (!fileHref) { return; }
    await downloadResult(fileHref);
    closeUnsuccessfulModal();
  };

  const onTryAgain = () => {
    setCountUnsuccessfulRequests(prevValue => (prevValue + 1));
    closeUnsuccessfulModal();
    setTimeout(() => {
      if (!linkRef || !linkRef.current) { return showUnsuccessfulRequestSlide(DEFAULT_MESSAGE); }
      linkRef.current.click();
    }, 1);
  };

  const anchorClassName = classNames(styles.anchor, props.className);
  const tryAgain = allowTryAgain && countUnsuccessfulRequests < MAX_UNSUCCESSFUL_REQUESTS;

  const forcedDisabled = disabled || disableAuthenticatedLinkDownload;

  return (
    <>
      <UnsuccessfulRequestModal
        isOpened={showUnsuccessfulModal}
        onCancel={onCancelModal}
        onContinueDownload={onContinueDownload}
        onTryAgain={onTryAgain}
        modalText={modalText}
        errorMessage={unsuccessfulModalErrorMessage}
        allowTryAgain={tryAgain}
        showScreenshotMessage={!tryAgain}
      />
      {/* Download breaks when we try to change the next link */}
      {/* eslint-disable-next-line */}
      <a
        className={anchorClassName}
        role="button"
        ref={linkRef}
        onClick={() => {
          if (forcedDisabled) {
            return;
          }
          setDownloading(true);
          setDisableAuthenticatedLinkDownload(true);
        }}
        aria-disabled={forcedDisabled}
      >
        {isFunction
          ? props.children(downloading)
          : props.children}
      </a>
    </>
  );
};
