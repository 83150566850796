import { config } from '@amp/config/config';
import { offlineCache } from '@amp/offline-cache/OfflineCache';
import { CacheEntity } from '@amp/offline-cache/OfflineCache.types';
import { IModuleWithFeatures } from '@amp/permissions/features.types';
import { cacheRequest } from '@amp/helpers/httpHelper';

export async function refreshFeatures (): Promise<string[]> {
  try {
    return (await cacheRequest(config.featureUrl, CacheEntity.features)) as string[];
  } catch (err) {
    console.log(err);
    return [];
  }
}

function getCachedFeatures (): string[] {
  return offlineCache.cacheQueryArray(CacheEntity.features) || [];
}

export function isFeatureInstalled (featureName: string): boolean {
  return getCachedFeatures().includes(featureName);
}

export async function refreshAllFeatures (): Promise<IModuleWithFeatures[]> {
  try {
    return (await cacheRequest(config.allFeaturesUrl, CacheEntity.allFeatures)) as IModuleWithFeatures[];
  } catch (err) {
    console.log(err);
    return [];
  }
}

export function getCachedAllFeatures (): IModuleWithFeatures[] {
  return offlineCache.cacheQueryArray(CacheEntity.allFeatures) || [];
}
