import gql from 'graphql-tag';

export const CONFIGURABLE_FORM_QUERY = gql`
  query GetTranspiledConfigurableFormQuestions($dto: ConfigurableFormQueryParams!) {
    GetTranspiledConfigurableFormQuestions(dto: $dto) {
      code
      title
      viewType
      multiple
      required
      suffix
      order
      additionalData
      requiredCondition {
        op
        code
        value
      }
      subQuestions {
        code
        title
        viewType
        multiple
        required
        additionalData
        answers {
          code
          description
        }
      }
      answers {
        code
        description
        subQuestions {
          code
          title
          order
          viewType
          multiple
          required
          additionalData
          answers {
            code
            description
          }
        }
      }
    }
  }
`;

export const CONFIGURABLE_FORM_ALL_BRANDS_QUERY = gql`
  query GetAllBrandTranspiledConfigurableFormQuestions($dto: AllBrandsConfigurableFormQueryParams!) {
    GetAllBrandTranspiledConfigurableFormQuestions(dto: $dto) {
      name
      brandTypeId
      questions {
        code
        title
        viewType
        multiple
        required
        suffix
        order
        additionalData
        requiredCondition {
          op
          code
          value
        }
        subQuestions {
          code
          title
          viewType
          multiple
          required
          additionalData
          answers {
            code
            description
          }
        }
        answers {
          code
          description
          subQuestions {
            code
            title
            order
            viewType
            multiple
            required
            additionalData
            answers {
              code
              description
            }
          }
        }
      }
    }
  }
`;
