import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { CacheEntity } from '@amp/offline-cache/OfflineCache.types';

const emptyCache = {
  [CacheEntity.client]: {},
  [CacheEntity.features]: [],
  [CacheEntity.isCachedOnce]: null,
  [CacheEntity.permissions]: [],
  [CacheEntity.presentations]: null,
  [CacheEntity.tactics]: null,
  [CacheEntity.tacticAssets]: null,
  [CacheEntity.tacticAssetHotspots]: null,
  [CacheEntity.tacticAssetVideos]: null,
  [CacheEntity.transactions]: []
};

const hasBeenCached = Object.values(CacheEntity).reduce((acc: {[cacheEntity: string]: boolean}, val: string) => {
  acc[val] = false;
  return acc;
}, {});

class OfflineCache {
  memoryCache = cloneDeep(emptyCache) as any;

  constructor () {
    this.loadDataToMemory(CacheEntity.transactions);
    this.loadDataToMemory(CacheEntity.isCachedOnce, false);
  }

  public isEverythingCachedOnce (): boolean {
    return this.memoryCache[CacheEntity.isCachedOnce] as boolean;
  }

  public storeNewData (cacheEntity: CacheEntity, data?: any) {
    if (data) {
      this.memoryCache[cacheEntity] = data;
      hasBeenCached[cacheEntity] = true;
    }
    if (window) {
      try {
        window.localStorage.setItem(cacheEntity, JSON.stringify(this.memoryCache[cacheEntity]));
      } catch (err) {
        // Handle the case where there wasn't enough space to store the
        // item in localStorage.
        console.warn(`Storage size exceeds quota for "${cacheEntity}": ${err}`);
      }
    }
  }

  public loadDataToMemory (cacheEntity: CacheEntity, fallbackValue: [] | boolean = []) {
    let cachedData;

    if (window) {
      cachedData = window.localStorage.getItem(cacheEntity);
    }

    if (!cachedData) {
      this.memoryCache[cacheEntity] = fallbackValue;
      hasBeenCached[cacheEntity] = true;
      return;
    }

    if (cachedData) {
      try {
        this.memoryCache[cacheEntity] = JSON.parse(cachedData);
      } catch (err) {
        this.memoryCache[cacheEntity] = fallbackValue;
        console.error(err);
      }
    }
    hasBeenCached[cacheEntity] = true;
  }

  public clearDataAfterLogout () {
    if (window) {
      for (const cacheEntity of Object.values(CacheEntity)) {
        window.localStorage.removeItem(cacheEntity);
      }
    }
    this.memoryCache = cloneDeep(emptyCache);
  }

  public cacheQueryArray<T> (cacheEntity: CacheEntity, data?: T[]): T[]| undefined {
    if (data && !isEqual(data, this.memoryCache[cacheEntity])) {
      this.storeNewData(cacheEntity, data);
    } else if (isEmpty(this.memoryCache[cacheEntity]) && !hasBeenCached[cacheEntity]) {
      this.loadDataToMemory(cacheEntity);
    }

    return this.memoryCache[cacheEntity] as T[];
  }

  public cacheQueryEntity<T> (cacheEntity: CacheEntity, data?: T): T| undefined {
    if (!isEmpty(data) && !isEqual(data, this.memoryCache[cacheEntity])) {
      this.storeNewData(cacheEntity, data);
    } else if (isEmpty(this.memoryCache[cacheEntity]) && !hasBeenCached[cacheEntity]) {
      this.loadDataToMemory(cacheEntity);
    }

    return this.memoryCache[cacheEntity] as T;
  }
}

export const offlineCache = new OfflineCache();
