import React, { useCallback, useContext, useState } from 'react';

export interface ConfirmAction {
  label: string;
  onClick?: () => void;
  primary?: boolean;
}

export interface ConfirmOptions {
  title: string;
  message: string;
  actions: ConfirmAction[];
}

export interface ConfirmProps extends ConfirmOptions {
  open: boolean;
  onClose: () => void;
}

type ConfirmContextValue = (options: ConfirmOptions) => void;

const ConfirmContext = React.createContext<ConfirmContextValue | null>(null);

interface ConfirmProviderProps {
  children: React.ReactNode;
  component: (props: ConfirmProps) => JSX.Element;
}

export const ConfirmProvider = (props: ConfirmProviderProps) => {
  const { children, component: Component } = props;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ConfirmOptions>({
    title: '',
    message: '',
    actions: []
  });

  const showConfirm = useCallback((options: ConfirmOptions) => {
    setOpen(true);
    setOptions(options);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ConfirmContext.Provider value={showConfirm}>
      {children}
      <Component {...options} open={open} onClose={handleClose} />
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const contextValue = useContext(ConfirmContext);
  if (!contextValue) {
    throw new Error('Wrap with ConfirmProvider');
  }
  return contextValue;
};
