import { IDivButtonProps } from '@amp/components/common/buttons/div-button/DivButton.types';
import React from 'react';
import styles from './DivButton.module.scss';

export const DivButton: React.FC<IDivButtonProps> = (props) => {
  const { children, onClick, className, style, key, 'data-testid': dataTestId, 'data-cy': dataCy, disabled } = props;
  return <div
    role={'button'}
    tabIndex={0}
    onClick={disabled ? undefined : onClick}
    onKeyDown={disabled ? undefined : () => undefined}
    className={`${styles.divButton} ${disabled ? styles.divButtonDisabled : ''} ${className}`}
    style={style}
    key={key}
    data-testid={dataTestId}
    data-cy={dataCy}
  >{children}</div>;
};
