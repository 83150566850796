import { useCallback, useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { ConfirmProps, ConfirmAction } from './Confirm.context';
import styles from './Confirm.module.scss';

export const Confirm = (props: ConfirmProps) => {
  const { open, title, onClose, message, actions } = props;

  const [activeIndex, setActiveIndex] = useState<number>();

  const handleAction = useCallback(
    async (action: ConfirmAction, index: number) => {
      setActiveIndex(index);
      if (action.onClick) {
        await action.onClick();
      }
      setActiveIndex(undefined);
      onClose();
    },
    [onClose]
  );

  const working = activeIndex !== undefined;

  return (
    <Modal
      className={styles.modal}
      open={open}
      closeOnEscape={!working}
      closeOnDimmerClick={!working}
      size="tiny"
      dimmer="blurring"
      onClose={onClose}
    >
      <Modal.Header className={styles.header}>{title}</Modal.Header>
      <Modal.Content className={styles.normalText}>{message}</Modal.Content>
      <Modal.Actions className={styles.actions}>
        {actions.map((action, index: number) => (
          <Button
            key={index}
            onClick={() => handleAction(action, index)}
            disabled={working}
            primary={action.primary}
            loading={activeIndex === index}
          >
            {action.label}
          </Button>
        ))}
      </Modal.Actions>
    </Modal>
  );
};
