import { RootContext } from '@amp/contexts/RootContext';
import { hasAccessToResource } from '@amp/permissions/permissions.helper';
import { Resource } from '@amp/permissions/resources.config';

import React, { useContext } from 'react';

interface IHasResourceProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  name: Resource;
  brandName?: string;
  restrictedRoleIds?: string[];
  accountTypeFlagId?: string;
}

export const HasResource: React.FC<IHasResourceProps> = props => {
  const { children, fallback, name, brandName, restrictedRoleIds } = props;
  const { ignoredBrandsResources, isAuthenticated } = useContext(RootContext);

  const displayResource = isAuthenticated && hasAccessToResource({
    brandName,
    ignoredBrandsResources,
    resource: name,
    restrictedRoleIds
  });

  return <>{displayResource ? children : fallback}</>;
};
