import { atom } from 'recoil';

export const isAmpClientConfigInitializedState = atom<boolean>({
  key: 'isAmpClientConfigInitialized',
  default: false
});

export const chosenBeliefMatrixBrandTypeIdsState = atom<string[]>({
  key: 'chosenBeliefMatrixBrandTypeIdsState',
  default: []
});
