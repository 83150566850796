import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation
} from '@apollo/client';

export const CONNECTION_PREFIX = {
  ASTO: 'ASTO',
  FILTER_LV: 'FILTER_LV'
};

const connections: { [key: string]: any } = {};

const subscriber = (operation: Operation, forward: NextLink) => (observer: any) => {
  const context = operation.getContext();

  const connectionHandle = forward(operation).subscribe({
    next: (...arg) => observer.next(...arg),
    error: (...arg) => {
      cleanUp();
      observer.error(...arg);
    },
    complete: (...arg) => {
      cleanUp();
      observer.complete(...arg);
    }
  });

  const cleanUp = () => {
    connectionHandle?.unsubscribe();
    delete connections[context.requestTrackerId];
  };

  if (!context.requestTrackerId) {
    return connectionHandle;
  }

  const controller = new AbortController();
  controller.signal.onabort = cleanUp;
  operation.setContext({
    ...context,
    fetchOptions: {
      signal: controller.signal,
      ...context?.fetchOptions
    }
  });

  if (connections[context.requestTrackerId]) {
    // If a controller exists, that means this operation should be aborted.
    connections[context.requestTrackerId]?.abort();
  }

  connections[context.requestTrackerId] = controller;

  return connectionHandle;
};

const apolloObserver = (operation: Operation, forward: NextLink) => new Observable<FetchResult>(
  subscriber(operation, forward)
);

export const cancelRequestLink = new ApolloLink(apolloObserver);

export const abortAllConnectionsWithPrefix = (prefix: string) => {
  for (const id of Object.values(connections)) {
    if (!id || !id.indexOf) {
      return;
    }
    if (id.indexOf(prefix) === 0) {
      connections[id]?.abort();
    }
  }
};
