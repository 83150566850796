/* eslint-disable jest/require-hook */
import dayjs, { Dayjs } from 'dayjs';

// Required to import if using "dayjs.utc()" functionality
// See: https://day.js.org/docs/en/plugin/utc
import utc from 'dayjs/plugin/utc';

// Required to import if using "dayjs.tz()" functionality
// See: https://day.js.org/docs/en/timezone/timezone
import timezone from 'dayjs/plugin/timezone';

// Required when you are trying to parse a specific already formatted
//  date like DD-MM-YYYY
// See: https://day.js.org/docs/en/plugin/custom-parse-format
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Required to use formats like "Do"
// See: https://day.js.org/docs/en/plugin/advanced-format
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Required to use functions like .quarter()
// See: https://day.js.org/docs/en/plugin/quarter-of-year
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import { DatetimeFormat, DatetimeFormatReverseMap } from '../types/datetime.types';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

class DateTimeService {
  constructor () {
    this.datetimeFormatReverseMap = this.buildDatetimeFormatReverseMap();
  }

  private datetimeFormatReverseMap: DatetimeFormatReverseMap;

  private buildDatetimeFormatReverseMap = (): DatetimeFormatReverseMap => {
    const map: DatetimeFormatReverseMap = {};
    const keys = Object.keys(DatetimeFormat);
    const values = Object.values(DatetimeFormat);
    values.forEach((value, index) => {
      map[value] = keys[index];
    });
    return map;
  };

  /**
   * Basically, DayJS doesn't seem to process Date objects well. It can handle undefined or string
   * values with no issues. So let's run this handle function to fix any unexpected errors.
   * @param date
   * @returns
   */
  private handleDate = (date: Date | string | undefined): string | undefined => {
    switch (typeof date) {
    case 'object':
      return date.toISOString();
    case 'string':
      return date;
    case 'undefined':
      return undefined;
    default:
      return undefined;
    }
  };

  public getFormattedDate = ({
    date,
    defaultValue = ''
  }: {
    date?: Date | string | null;
    defaultValue?: string;
  }): string => {
    if (date) {
      const theDate = typeof date === 'string' ? new Date(date) : date;
      return Intl.DateTimeFormat('en', {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      }).format(new Date(theDate.toLocaleString('en-us', {
        timeZone: 'America/New_York'
      })));
    }
    return defaultValue;
  };

  public getDefaultDatetimeObject = (): Dayjs => {
    return dayjs();
  };

  public getDatetimeObject = (date: Date | string | undefined): Dayjs => {
    return dayjs(this.handleDate(date));
  };

  public getDateObject = (date: Date | string | undefined): Date => {
    return dayjs(this.handleDate(date)).toDate();
  };

  public getDateQuarter = (date: Date | string | undefined): number => {
    return dayjs(this.handleDate(date)).quarter();
  };

  public getDatetimeObjectQuarter = (dayjs: Dayjs): number => {
    return dayjs.quarter();
  };

  /**
   * Unfortunately, vanilla JS Date.getDay() gives you the day of the week by number
   * instead of the actual day as a number. Here's a custom function needed for testing
   * @param date
   * @returns the day of the month as a number
   */
  public getDay = (date: Date | string | undefined): number => {
    const convertedDate = this.getDateObject(this.handleDate(date));
    return convertedDate.getDate();
  };

  /**
   * @param date
   * @returns Returns month by number starting at 0 for January
   */
  public getMonth = (date: Date | string | undefined): number => {
    const convertedDate = this.getDateObject(this.handleDate(date));
    return convertedDate.getMonth();
  };

  public getYear = (date: Date | string | undefined): number => {
    const convertedDate = this.getDateObject(this.handleDate(date));
    return convertedDate.getFullYear();
  };

  public getDefaultDateForAddedDays = (addValue: number, date?: Date | string): Date => {
    if (!date) {
      return dayjs().add(addValue, 'days').toDate();
    }
    const datetimeObject = this.getDatetimeObject(date);
    return datetimeObject.add(addValue, 'days').toDate();
  };

  public getDefaultDateForSubtractedDays = ({
    dateToSubtractFrom,
    subtractValue
  }: {
    dateToSubtractFrom?: Date | string | undefined;
    subtractValue: number;
  }): string => {
    const convertedDayOfTheMonth = this.getDatetimeObject(dateToSubtractFrom).date();
    return dayjs().subtract(convertedDayOfTheMonth - subtractValue, 'days').toISOString();
  };

  public getDateWithCustomFormat = ({
    date,
    dateFormat,
    finalFormat
  }: {
    date?: Date | string | number | undefined,
    dateFormat?: string | undefined,
    finalFormat: string
  }): string => {
    try {
      const enumKey = this.getKeyByValueForDatetimeFormat(finalFormat);
      const dayJsDate = dateFormat ? dayjs(date, dateFormat) : dayjs(date);
      const convertedDate = date ? dayJsDate : dayjs();
      return convertedDate.format(DatetimeFormat[enumKey]);
    } catch (err) {
      throw new Error(`Could not format date for:
        \ndate = "${date}"
        \ndateFormat = "${dateFormat}"
        \nfinalFormat = "${finalFormat}"
        \nerror details = ${err}
      `);
    }
  };

  public getDateByTimezone = ({
    date,
    timezone,
    customFormat = undefined,
    clearTime = false
  }: {
    date: Date | string | number | undefined;
    timezone: string;
    customFormat?: string | undefined;
    clearTime?: boolean | undefined;
  }): dayjs.Dayjs => {
    if (!customFormat) {
      return dayjs.tz(date, timezone);
    }
    const convertedDate = dayjs(date, customFormat as dayjs.OptionType).tz(timezone);
    if (clearTime) {
      return convertedDate.startOf('day');
    }
    return convertedDate.utcOffset(0);
  };

  public getFormattedDateByTimezone = ({
    date,
    timezone,
    format,
    customFormat = undefined,
    clearTime = false
  }: {
    date: Date | string | number | undefined;
    timezone: string;
    format: string;
    customFormat?: string | undefined;
    clearTime?: boolean | undefined;
  }): string => {
    try {
      const timezonedDate = this.getDateByTimezone({ date, timezone, customFormat, clearTime });
      if (!format) {
        return timezonedDate.toString();
      }
      const enumKey = this.getKeyByValueForDatetimeFormat(format);
      return timezonedDate.format(DatetimeFormat[enumKey]);
    } catch (err) {
      throw new Error(`Could not format timezoned date for:
        \ndate = "${date}"
        \ntimezone = "${timezone}"
        \nformat = "${format}"
        \nerror details = ${err}
      `);
    }
  };

  public getUtcDate = ({
    date,
    fromFormat
  }: {
    date: Date | string | undefined,
    fromFormat?: string
  }): dayjs.Dayjs => {
    const handledDate = this.handleDate(date);
    const convertedValue = !fromFormat ? dayjs.utc(handledDate) : dayjs.utc(handledDate, fromFormat);
    if (!convertedValue.isValid()) {
      throw new Error(`"Invalid Date" returned while converted to UTC date for: "${date}"`);
    }
    return convertedValue.utcOffset(0);
  };

  public getUtcDateAsString = ({
    date,
    fromFormat
  }: {
    date: Date | string | undefined,
    fromFormat?: string
  }): string => {
    return this.getUtcDate({ date, fromFormat }).toString();
  };

  public getUtcDateAsDate = ({
    date,
    fromFormat
  }: {
    date: Date | string | undefined,
    fromFormat?: string
  }): Date => {
    return this.getUtcDate({ date, fromFormat }).toDate();
  };

  public getUtcDateAsDateClearedTime = ({
    date,
    fromFormat
  }: {
    date: Date | string | undefined,
    fromFormat?: string
  }): Date => {
    return this.getUtcDate({ date, fromFormat }).startOf('day').toDate();
  };

  public getUtcDateWithCustomFormat = ({
    date,
    toFormat,
    fromFormat,
    clearTime = false
  }: {
    date: Date | string | undefined,
    toFormat: string,
    fromFormat?: string,
    clearTime?: boolean | undefined
  }): string => {
    try {
      this.getKeyByValueForDatetimeFormat(toFormat);
      const convertedUtcDate = clearTime
        ? this.getUtcDate({ date, fromFormat }).startOf('day')
        : this.getUtcDate({ date, fromFormat });
      return convertedUtcDate.format(toFormat);
    } catch (err) {
      throw new Error(`Could not format UTC date for:
        \ndate = "${date}"
        \ntoFormat = "${toFormat}"
        \nfromFormat = "${fromFormat}"
        \nerror details = ${err}
      `);
    }
  };

  public getDateFromFormatToFormat = ({
    date,
    fromFormat,
    toFormat
  }: {
    date: Date | string | undefined,
    fromFormat: string,
    toFormat: string
  }): string => {
    return dayjs(this.handleDate(date), fromFormat).format(toFormat);
  };

  public getDateWithSlashesCustom = (date?: Date | null): string | undefined => {
    if (!date) {
      return;
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;

    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      return;
    }

    return `${this.getTwoDigitNumber(month)}/${this.getTwoDigitNumber(day)}/${this.getTwoDigitNumber(year)}`;
  };

  private getTwoDigitNumber = (numberInput: number): string => {
    return `${numberInput < 10 ? '0' : ''}${numberInput}`;
  };

  /**
   * @param firstDateValue: The date we are subtracting from (should be the later date)
   * @param secondDateValue: The date we are subtracting (should be the earlier date)
   * @returns
   */
  public getDiffByDays = ({
    firstDateValue,
    secondDateValue,
    startOfDay
  }: {
    firstDateValue?: Date | string | number | undefined;
    secondDateValue?: Date | string | number | undefined;
    startOfDay?: boolean;
  }): number => {
    try {
      const convertedFirstDateValue = !firstDateValue
        ? this.getDefaultDatetimeObject()
        : dayjs(firstDateValue);
      const convertedSecondDateValue = !secondDateValue
        ? this.getDefaultDatetimeObject()
        : dayjs(secondDateValue);

      if (startOfDay) {
        return convertedFirstDateValue.startOf('day').diff(convertedSecondDateValue.startOf('day'), 'days');
      }
      return convertedFirstDateValue.diff(convertedSecondDateValue, 'days');
    } catch (err) {
      throw new Error(`Could not get date difference by days for:
        \nfirstDateValue = "${firstDateValue}"
        \nsecondDateValue = "${secondDateValue}"
        \nstartOfDay = "${startOfDay}"
        \nerror details = ${err}
      `);
    }
  };

  public getDiffBySeconds = ({
    firstDateValue,
    secondDateValue
  }: {
    firstDateValue?: Date | string | number | undefined;
    secondDateValue?: Date | string | number | undefined;
  }): number => {
    try {
      const convertedFirstDateValue = !firstDateValue
        ? this.getDefaultDatetimeObject()
        : dayjs(firstDateValue);
      const convertedSecondDateValue = !secondDateValue
        ? this.getDefaultDatetimeObject()
        : dayjs(secondDateValue);

      return convertedFirstDateValue.diff(convertedSecondDateValue, 'seconds');
    } catch (err) {
      throw new Error(`Could not get date difference by seconds for:
        \nfirstDateValue = "${firstDateValue}"
        \nsecondDateValue = "${secondDateValue}"
        \nerror details = ${err}
      `);
    }
  };

  public checkIfDateIsValid = (date: Date | string | undefined): boolean => {
    return dayjs(date).isValid();
  };

  public checkIfDateIsAfter = (date: Date | string | undefined, afterDate: Date | string | undefined): boolean => {
    return dayjs(date).isAfter(afterDate);
  };

  public checkIfUTCDateIsSame = ({ firstDate, secondDate }: {
    firstDate: Date,
    secondDate: Date
  }): boolean => {
    if (!this.checkIfDateIsValid(firstDate) || !this.checkIfDateIsValid(secondDate)) {
      throw new Error(`Could not check dates as one or both are invalid dates:
        \nfirstDate = "${firstDate}"
        \nsecondDate = "${secondDate}"`);
    }
    const checkDay = firstDate.getUTCDate() === secondDate.getUTCDate();
    const checkMonth = firstDate.getUTCMonth() === secondDate.getUTCMonth();
    const checkYear = firstDate.getUTCFullYear() === secondDate.getUTCFullYear();
    return checkDay && checkMonth && checkYear;
  };

  private getKeyByValueForDatetimeFormat = (value: string): keyof typeof DatetimeFormat => {
    const key = this.datetimeFormatReverseMap[value] as keyof typeof DatetimeFormat;
    if (!key) {
      throw new Error(`Could not find format pattern for datetime: ${value}`);
    }
    return key;
  };
}

export const DatetimeHelper = new DateTimeService();
