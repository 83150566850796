import { toast } from 'react-semantic-toasts';

type FlashType = 'info' | 'success' | 'warning' | 'error';
type FlashSizeType = 'mini' | 'tiny' | 'small' | 'large' | 'big' | 'huge' | 'massive'

interface FlashConfig {
  type: FlashType;
  title: string;
  description: string;
  size?: FlashSizeType;
  time?: number;
}

export interface IFlashMessageFunctions {
  flashCustom: (config: FlashConfig) => void,
  flashError: (message: string) => void,
  flashInfo: (message: string) => void,
  flashSuccess: (message: string) => void,
  flashWarning: (message: string) => void
}

export const useFlashMessage = (): IFlashMessageFunctions => {
  const flashCustom = (config: FlashConfig) => {
    toast(config);
  };

  const flashError = (error: string) => {
    toast({
      type: 'error',
      description: error,
      title: '',
      size: 'small'
    });
  };

  const flashInfo = (text: string) => {
    toast({
      type: 'info',
      description: text,
      title: '',
      size: 'small'
    });
  };

  const flashWarning = (text: string) => {
    toast({
      type: 'warning',
      description: text,
      title: '',
      size: 'small'
    });
  };

  const flashSuccess = (text: string) => {
    toast({
      type: 'success',
      description: text,
      title: '',
      size: 'small'
    });
  };

  return { flashCustom, flashError, flashInfo, flashSuccess, flashWarning };
};
