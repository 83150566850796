import React from 'react';
import DatePicker from 'react-datepicker';
import { Question, SurveyQuestionElementBase } from 'survey-react';

import { DatetimeHelper } from 'amp-client-config';

export const SURVEY_DATEPICKER_QUESTION_TYPE = 'datepicker';

export class QuestionDatepickerModel extends Question {
  public getType (): string {
    return SURVEY_DATEPICKER_QUESTION_TYPE;
  }

  public setValue (value: string): void {
    this.value = value;
  }
}

export class QuestionDatepicker extends SurveyQuestionElementBase {
  protected get question (): QuestionDatepickerModel {
    return this.questionBase as unknown as QuestionDatepickerModel;
  }

  renderElement (): JSX.Element {
    return (
      <div className={this.question.cssClasses.root}>
        <DatePicker
          selected={this.question.value ? DatetimeHelper.getDateObject(this.question.value) : undefined}
          onChange={(e: Date | null) => {
            this.question.setValue(e?.toString() ?? '');
          }}
          dateFormat="MMMM d, yyyy"
          placeholderText="Select a date"
          isClearable
        />
      </div>
    );
  }
}
