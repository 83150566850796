import { cloneDeep } from 'lodash';

export const getPropertyFromStringDotNotation = (
  mainObject: any,
  dotNotationString: string,
  objectWithInterpolationData?: any
): any => {
  let innerProperty: any = cloneDeep(mainObject);
  if (checkIfStringContainsInterpolation(dotNotationString)) {
    dotNotationString = getPropertyFromStringWithInterpolation(
      objectWithInterpolationData,
      dotNotationString
    );
  }
  dotNotationString.split('.').forEach((level: string) => {
    if (innerProperty) {
      innerProperty = innerProperty[level];
    }
  });
  return innerProperty;
};

export const checkIfStringContainsInterpolation = (dotNotationString: string): boolean => {
  if (!dotNotationString) {
    return false;
  }
  return dotNotationString.includes('{{') && dotNotationString.includes('}}');
};

export const getPropertyFromStringWithInterpolation = (
  objectWithInterpolationData: any,
  stringWithInterpolation: string
) => {
  const regExp: RegExp = /\{{(.*?)\}}/g;
  const interpolatedParts = stringWithInterpolation.match(regExp);
  let stringWithNoInterpolation = '';
  if (interpolatedParts) {
    interpolatedParts.forEach(part => {
      const partDotNotation = part.substring(2, part.length - 2);
      const completePart = getPropertyFromStringDotNotation(
        objectWithInterpolationData,
        partDotNotation
      );
      const splitByPart = stringWithInterpolation.split(part);
      stringWithNoInterpolation = `${splitByPart[0]}${completePart}${splitByPart[1]}`;
    });
  }
  return stringWithNoInterpolation;
};
