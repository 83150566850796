import { IDownloadItem } from '@amp/components/page-layout/header/downloads-popup/DownloadsPopup.types';

export const normalizeDownloadFilename = (name: string): string => {
  return name.toLowerCase().trim()
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-')
    .replace(/[^a-z0-9-]+/gi, '');
};

export const getDownloadTitle = (item: IDownloadItem) => {
  const accountName = item.accountSegmentType?.account?.accountType?.name ??
    item.accountSegmentType?.sharedAccount?.accounts?.[0]?.accountType.name;

  const segmentTypeName = item.accountSegmentType?.segmentType.name;

  switch (item.reportType) {
  case 'ast-slides':
    return `${accountName} - ${segmentTypeName}`;
  case 'planning-slides':
    return 'Planning Slides Report';
  case 'planning-excel':
    return 'Planning Excel Report';
  case 'event-tracker-slides':
    return 'Event Tracker Slides Report';
  case 'belief-matrix-history':
    return 'Belief Matrix History';
  case 'goals-and-objectives-history':
    return 'Goals and Objectives History';
  default:
    return 'Unknown Report';
  }
};

const forceDownload = (url: string, filename: string) => {
  const a = document.createElement('a');

  a.download = filename;
  a.href = url;

  document.body.appendChild(a);

  a.click();
  a.remove();
};

export const downloadResource = (url: string, filename: string) => {
  return fetch(url, {
    headers: new Headers({
      Origin: window.location.origin
    }),
    mode: 'cors'
  })
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch(e => console.error(e));
};
