import { find, get, isEmpty } from 'lodash';

import { AccountTypeFlag, FormType, MixedTeam, IStrategiesAndTacticsForm, MixedLibrary, MixedSegment, IForm } from '../types/client.types';
import { IQuestionToAnswer, ISTBrand, ISTQuestion } from '../types/format.types';
import { FormActionType } from '../types/formview.types';
import { IOneFormOptions } from '../types/library.types';
import { getFormContent } from '../init/init';

export const getLibrary = (): MixedLibrary | undefined => {
  return getFormContent() as MixedLibrary;
};

export const getFirstForm = (): IForm | undefined => {
  const library = getLibrary();
  if (!library) {
    return {};
  }
  for (const team of Object.keys(library)) {
    const teamLibrary = library[team as keyof MixedLibrary];
    if (!teamLibrary) {
      return {};
    }
    for (const accountTypeFlag of Object.keys(teamLibrary)) {
      const accountTypeFlagLibrary = teamLibrary[accountTypeFlag];
      if (!accountTypeFlagLibrary) {
        return {};
      }
      const segmentTypes = Object.keys(accountTypeFlagLibrary);
      if (isEmpty(segmentTypes)) {
        continue;
      }
      const firstSegmentType = segmentTypes[0];
      return get(getLibrary(), `[${team}].[${accountTypeFlag}].[${firstSegmentType}]`, {});
    }
  }
  return {};
};

export const getAllFormsForTeamByAccountAndSegmentType = (
  team: MixedTeam,
  accountTypeFlag: AccountTypeFlag,
  segmentType: MixedSegment
): IForm | undefined => {
  return get(getLibrary(), `[${team}].[${accountTypeFlag}].[${segmentType}]`, {});
};

export const getOneFormForTeam = (
  team: MixedTeam,
  accountTypeFlag: AccountTypeFlag,
  segmentType: MixedSegment,
  form: FormType
): IQuestionToAnswer | FormActionType | IStrategiesAndTacticsForm | undefined => {
  const allForms = getAllFormsForTeamByAccountAndSegmentType(team, accountTypeFlag, segmentType);
  return allForms?.[form] ?? {};
};

export const getOneFormForTeamWithOptions = (
  formOptions: IOneFormOptions
): IQuestionToAnswer | FormActionType | IStrategiesAndTacticsForm | undefined => {
  if (!formOptions.form) {
    return undefined;
  }
  const { team, accountTypeFlag, segmentType } = formOptions;
  const allForms = getAllFormsForTeamByAccountAndSegmentType(team, accountTypeFlag, segmentType);
  return allForms?.[formOptions.form] ?? {};
};

export const getInstalledQuestions = (questions: ISTQuestion[], installedFeatures: string[] = []) => {
  return questions.filter(question => {
    const questionFeature = question?.additionalData?.feature;
    return !questionFeature || installedFeatures.includes(questionFeature);
  });
};

export const getNestedForm = (
  specificForm: IForm,
  formType: FormType,
  formProperty: string
): IQuestionToAnswer | undefined => {
  switch (formType) {
  case 'strategiesAndTactics':
    return get(specificForm, `${formProperty}`, specificForm) as IQuestionToAnswer;
  default:
    return undefined;
  }
};

export const getBrandSpecificForm = (
  form: IQuestionToAnswer,
  brandName: string
): ISTBrand | undefined => {
  if (!form || !form.brands) {
    return undefined;
  }
  return find(form.brands, el => el.brandName.toLowerCase() === brandName.toLowerCase());
};
