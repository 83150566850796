import { useContext, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { RootContext } from '@amp/contexts/RootContext';
import { UsageReportModalHookResponse } from './UsageReportModal.types';

export const useUsageReportModal = (): UsageReportModalHookResponse => {
  const {
    isUsageReportModalOpen,
    setIsUsageReportModalOpen
  } = useContext(RootContext);

  const todayDate = new Date();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [minDate, setMinDate] = useState<Date | null>(null);
  const [maxDate, setMaxDate] = useState<Date | null>(todayDate);

  const startDateRef = useRef<DatePicker>(null);
  const endDateRef = useRef<DatePicker>(null);

  const onClose = () => {
    setIsUsageReportModalOpen(false);
    setStartDate(null);
    setEndDate(null);
  };

  const onStartDateChange = (date: Date | null) => {
    if (!date) {
      setStartDate(null);
      setMaxDate(todayDate);
      return;
    }

    if (endDate && date && date > endDate) {
      setEndDate(null);
      setMinDate(null);
    }

    setStartDate(date);
    const maxDate = new Date(date?.getTime() || 0);
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    setMaxDate(todayDate > maxDate ? maxDate : todayDate);
  };

  const onEndDateChange = (date: Date | null) => {
    if (!date) {
      setEndDate(null);
      setMinDate(null);
      return;
    }

    if (startDate && date && date < startDate) {
      setStartDate(null);
      setMaxDate(todayDate);
    }

    setEndDate(date);
    const minDate = new Date(date?.getTime() || 0);
    minDate.setFullYear(minDate.getFullYear() - 1);
    setMinDate(minDate);
  };

  return {
    endDate,
    endDateRef,
    isUsageReportModalOpen,
    maxDate,
    minDate,
    onClose,
    onEndDateChange,
    onStartDateChange,
    startDate,
    startDateRef
  };
};
