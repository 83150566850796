import { ViewType } from '../types/formview.types';
import { ISTQuestion, IQuestion } from '../types/format.types';

type QuestionBank = { [x in ViewType]?: ISTQuestion | IQuestion};
export const questionBank:QuestionBank = {
  text: {
    id: 1,
    code: 'title',
    tableCode: 'data.title',
    fieldCode: 'data.title',
    title: 'Title',
    viewType: 'text',
    required: true,
    new: false,
    updated: false,
    turnOnDate: undefined,
    turnOffDate: undefined
  },
  'multi-text': {
    id: 1,
    code: 'manufacturer_strengths',
    tableCode: 'data.brands.{{brand.id}}.manufacturer_strengths',
    fieldCode: 'data.brands.{{brand.id}}.manufacturer_strengths',
    title: 'Manufacturer Strengths vs. Competition (List up to 3)',
    viewType: 'multi-text',
    additionalData: {
      maxLength: 100
    },
    answers: [
      {
        id: 1,
        code: 'manufacturer_strengths_answer_1',
        description: '',
        type: 'string'
      }
    ]
  },
  'conditional-question': {
    code: 'has_policy',
    tableCode: 'data.brands.{{brand.id}}.has_policy',
    fieldCode: 'data.brands.{{brand.id}}.has_policy',
    required: true,
    new: false,
    updated: false,
    id: 15,
    viewType: 'conditional-question',
    title: 'Does the Account have a written Pompe Policy?',
    answers: [
      {
        new: false,
        updated: false,
        code: 'yes',
        description: 'The account has a written Pompe policy',
        subQuestions: [
          {
            code: 'policy_last_review_date',
            tableCode: 'data.brands.{{brand.id}}.policy_last_review_date',
            fieldCode: 'data.brands.{{brand.id}}.policy_last_review_date',
            required: true,
            new: false,
            updated: false,
            id: 16,
            viewType: 'date',
            title: 'Date Policy Was Last Reviewed',
            presentAs: 'The account has a written policy for Pompe that was last reviewed on %s'
          }
        ],
        id: 1
      },
      {
        new: false,
        updated: false,
        code: 'no',
        description: 'The account does not have a written Pompe policy',
        id: 2
      },
      {
        new: false,
        updated: false,
        code: 'wip',
        description: 'We are currently seeking to determine whether the account has a written Pompe policy',
        id: 3
      }
    ]
  } as IQuestion,
  number: {
    id: 6,
    section: 2,
    code: 'goal_market_share',
    tableCode: 'data.brands.{{brand.id}}.goals.goal_market_share',
    fieldCode: 'brands.{{brand.id}}.goals.goal_market_share',
    viewType: 'number',
    title: 'TRx market share goal at the account:',
    requiredCondition: {
      op: 'eq',
      code: 'data.brands.{{brand.id}}.goals.trx_market_share.value',
      value: 'increase'
    },
    suffix: '%'
  },
  dropdown: {
    id: 6,
    code: 'directInfluence',
    tableCode: 'data.directInfluence',
    fieldCode: 'data.directInfluence',
    title: 'What direct influence does this influencer have on access decisions?',
    note: 'Note: answers in dropdown are from MOST to LEAST influential / relevant.',
    viewType: 'dropdown',
    required: true,
    new: false,
    updated: false,
    turnOnDate: undefined,
    turnOffDate: undefined,
    answers: [
      {
        id: 1,
        code: 'direct_influence_6',
        description: 'Chair of the P&T',
        new: false,
        updated: false,
        turnOnDate: undefined,
        turnOffDate: undefined
      },
      {
        id: 2,
        code: 'direct_influence_5',
        description: 'Develops Policy',
        new: false,
        updated: false,
        turnOnDate: undefined,
        turnOffDate: undefined
      },
      {
        id: 3,
        code: 'direct_influence_4',
        description: 'P&T, Voting Member',
        new: false,
        updated: false,
        turnOnDate: undefined,
        turnOffDate: undefined
      },
      {
        id: 4,
        code: 'direct_influence_3',
        description: 'P&T, Non-Voting Member',
        new: false,
        updated: false,
        turnOnDate: undefined,
        turnOffDate: undefined
      },
      {
        id: 5,
        code: 'direct_influence_2',
        description: 'Executes Contracts',
        new: false,
        updated: false,
        turnOnDate: undefined,
        turnOffDate: undefined
      },
      {
        id: 6,
        code: 'direct_influence_1',
        description: 'None of the above',
        new: false,
        updated: false,
        turnOnDate: undefined,
        turnOffDate: undefined
      }
    ]
  },
  date: {
    id: 1,
    code: 'dateCode1',
    tableCode: 'data.dateCode1',
    fieldCode: 'data.dateCode1',
    title: 'Date1',
    viewType: 'date',
    required: true,
    new: false,
    updated: false,
    turnOnDate: undefined,
    turnOffDate: undefined
  }
};
