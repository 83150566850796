import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';

import { DownloadReportsContext } from '@amp/contexts/DownloadReportsContext';
import { IDownloadItem } from '../DownloadsPopup.types';
import { DELETE_REPORT } from '@amp/graphql/mutations/Report';

export const useDownloadItem = ({ item }: {
  item: IDownloadItem
}) => {
  const { refetchUserDownloadItems, showReportFailedMessage } = useContext(DownloadReportsContext);
  const apolloClient = useApolloClient();

  const downloadCreationDate = new Date(item.updatedAt);
  const thresholdToDelete = new Date();

  thresholdToDelete.setDate(thresholdToDelete.getDate() - 1);
  const dateDiffInMs = downloadCreationDate.getTime() - thresholdToDelete.getTime();
  const differenceMinutes = Math.floor(dateDiffInMs / (1000 * 60));
  const differenceHours = Math.floor(differenceMinutes / 60);
  const timeToExpire = differenceHours > 12
    ? '1 day'
    : differenceMinutes >= 60
      ? `${differenceHours} hour${differenceHours > 1 ? 's' : ''}`
      : `${differenceMinutes} minute${differenceMinutes > 1 ? 's' : ''}`;

  const deleteDownloadItem = async () => {
    await apolloClient.mutate({
      mutation: DELETE_REPORT,
      variables: {
        reportId: item.id
      }
    });
    await refetchUserDownloadItems();
  };

  return {
    deleteDownloadItem,
    showReportFailedMessage,
    timeToExpire
  };
};
