import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import jsonwebtoken from 'jsonwebtoken';
import axios from 'axios';

import { logger } from '@amp/logger/logger';
import { config } from '@amp/config/config';
import { cancelRequestLink } from '@amp/helpers/cancelRequestLink';
import { refreshPermissions } from '@amp/permissions/permissions.helper';
import { refreshAllFeatures, refreshFeatures } from '@amp/permissions/features.helper';
import { updateAmpClientConfigData } from '@amp/helpers/updateAmpClientConfigDataHelper';
import { getErrorMessage } from '@amp/components/common/error/error';

export interface IAuthenticatedUser {
  userId: string;
  email: string;
  firstName: string;
  isTestUser: boolean;
  lastName: string;
  passwordExpired: boolean;
  passwordExpiresAt: Date;
  regionId: number;
  regionCode: string;
  roles: string[];
  teamIds: string[];
}

const httpLink = createUploadLink({
  uri: config.graphqlUrl,
  headers: { 'Apollo-Require-Preflight': 'true' }
});

class AuthzRefreshError extends Error {
  constructor (message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthzHelpers {
  public refreshPeriod = 18 * 60 * 1000; // 18 minutes
  public apolloClient = new ApolloClient({
    link: from([cancelRequestLink, httpLink]),
    cache: new InMemoryCache(),
    queryDeduplication: false
  });

  public getHttpClient () {
    const jwt = localStorage.getItem('token');
    return axios.create({
      headers: {
        authorization: jwt
          ? `Bearer ${jwt}`
          : ''
      }
    });
  }

  public createApolloClient (errorHandler: any) {
    const authLink = setContext((_, { headers }) => {
      const jwt = localStorage.getItem('token');

      return {
        headers: {
          ...headers,
          authorization: jwt
            ? `Bearer ${jwt}`
            : ''
        }
      };
    });

    this.apolloClient = new ApolloClient({
      link: from([cancelRequestLink, errorHandler, authLink.concat(httpLink)]),
      cache: new InMemoryCache({ addTypename: false }),
      queryDeduplication: false
    });
  }

  public async refreshJwt (jwt: string) {
    try {
      const res = await fetch(`${config.loginUrl}/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt
        }
      });
      if (!res.ok) {
        throw new AuthzRefreshError(res.statusText);
      }
      const data = await res.json();
      return data.jwt;
    } catch (err) {
      if (getErrorMessage(err).indexOf('Failed to fetch') > 0) {
        throw new Error('No Network');
      }
      throw err;
    }
  }

  public deleteJwtFromLocalStorage () {
    localStorage.removeItem('token');
  }

  public setJwtToLocalStorage (jwt: string | undefined) {
    if (jwt) {
      localStorage.setItem('token', jwt);
    } else {
      this.deleteJwtFromLocalStorage();
    }
  }

  public addHandlerForLogoutInDifferentTab (handleLogout: Function) {
    const handler = (event: any) => {
      if (event.key === 'token') {
        const token = localStorage.getItem('token');
        if (!token) {
          handleLogout();
        }
      }
    };
    window.addEventListener('storage', (e) => handler(e));

    return window.removeEventListener('storage', handler);
  }

  public createJwtRefreshInterval (handleLogout: Function) {
    return setInterval(async () => {
      this.refreshAuthzInBackground(handleLogout);
    }, this.refreshPeriod);
  }

  public async refreshAuthzInBackground (handleLogout: Function, setAuthenticatedUser?: Function) {
    const jwt = localStorage.getItem('token');
    const isOnlineFromNavigator = navigator.onLine;

    if (!jwt) {
      return;
    }

    try {
      if (isOnlineFromNavigator) {
        const newJwt = await this.refreshJwt(jwt);
        this.setJwtToLocalStorage(newJwt);
      }
    } catch (err) {
      logger.error(err as string);

      if (err instanceof AuthzRefreshError) {
        handleLogout();
      }
    }

    if (setAuthenticatedUser) {
      const newAuthenticatedUser = jsonwebtoken.decode(jwt) as IAuthenticatedUser;

      setAuthenticatedUser(newAuthenticatedUser);
    }

    await Promise.all([
      refreshPermissions(),
      refreshFeatures(),
      refreshAllFeatures(),
      updateAmpClientConfigData()
    ]);
  }
}

export const authzHelpers = new AuthzHelpers();
