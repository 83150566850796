import gql from 'graphql-tag';

export const GET_USERS_BY_REGION = gql`
  query GetUsersByRegion($regionId: Float!) {
    GetUsersByRegion(regionId: $regionId) {
      id
      firstName
      lastName
    }
  }
`;

export const GET_ALL_USERS_FROM_ALLOWED_REGIONS = gql`
  query GetAllUsersFromAllowedRegions {
    GetAllUsersFromAllowedRegions {
      id
      firstName
      lastName
      regionId
      region {
        code
      }
    }
  }
`;

export const GET_USERS_BY_REGIONS = gql`
  query GetUsersByRegions($regionIds: [Float!]!) {
    GetUsersByRegions(regionIds: $regionIds) {
      id
      firstName
      lastName
      region {
        code
      }
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    GetAllUsers {
      id
      firstName
      lastName
      email
      regionId
      region {
        code
      }
      isTestUser
      isNewUser
      userToRole {
        roleId
      }
      brandTypeRestrictions {
        brandTypeId
      }
    }
  }
`;

export const GET_ALL_REGIONS = gql`
  query GetAllRegions {
    GetAllRegions {
      id
      code
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query GetAllRoles {
    GetAllRoles {
      id
      restrictModule
    }
  }
`;

export const GET_RESTRICTED_ROLES = gql`
  query GetRestrictedRoles {
    GetRestrictedRoles {
      id
    }
  }
`;

export const UPLOAD_USER_ROSTER = gql`
  mutation UploadUserRoster($input: UploadUserRosterInput!) {
    UploadUserRoster(uploadUserRosterInput: $input) {
      updatedUsers
      deletedUsers
      createdUsers
      uploadedUsers
      errors
    }
  }
`;
