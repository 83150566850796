let cachedFormContentStore: any;

export const getFormContent = () => {
  if (cachedFormContentStore) {
    return cachedFormContentStore;
  }
  return null;
};

export const initLibrary = (config: any) => {
  cachedFormContentStore = deepFreeze(config);
};

function deepFreeze<T> (object: T): T {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  for (const name of propNames) {
    const value = object[name as keyof T];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}
