import { Route } from 'react-router';
import { PageLayout } from '../page-layout/PageLayout';

export const PublicRoute = ({
  component: Component,
  layout: Layout = PageLayout,
  ...rest
}: any) => {
  return (
    <>
      <Route
        {...rest}
        render={matchProps => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
    </>
  );
};
