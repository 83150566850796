import { IDBPDatabase, openDB } from 'idb';
import { AmpDb } from './IndexedDb.schema';
import { IDB_TABLES } from '@amp/indexed-db/IndexedDb.config';

const DB_NAME = 'amp-db';
const CURRENT_VERSION = 2;

export class IndexedDb {
  private static db: IDBPDatabase<AmpDb> | undefined;

  private static async initializeDb () {
    if (IndexedDb.db) {
      return;
    }
    IndexedDb.db = await openDB<AmpDb>(DB_NAME, CURRENT_VERSION, {
      upgrade (db: IDBPDatabase<AmpDb>) {
        // Get the list of existing stores, if any
        const stores = db.objectStoreNames;
        if (!stores.contains(IDB_TABLES.CONFIG)) { db.createObjectStore(IDB_TABLES.CONFIG); }
        if (!stores.contains(IDB_TABLES.OFFLINE_APP_INSIGHTS_EVENTS)) { db.createObjectStore(IDB_TABLES.OFFLINE_APP_INSIGHTS_EVENTS); }
        if (!stores.contains(IDB_TABLES.SYNCED_DVA_ASSETS)) { db.createObjectStore(IDB_TABLES.SYNCED_DVA_ASSETS); }
      },
      terminated () {
        IndexedDb.db = undefined;
      }
    });
    if (!IndexedDb.db) {
      throw new Error('Indexed DB is not initialized');
    }
  }

  public static async putValue (table: IDB_TABLES, key: string, value: (boolean | Date | string)): Promise<void> {
    await IndexedDb.initializeDb();
    await IndexedDb?.db?.put(table, value, key);
  }

  public static async getValue (table: IDB_TABLES, key: string): Promise<boolean | Date | string | undefined> {
    await IndexedDb.initializeDb();
    return IndexedDb?.db?.get(table, key);
  }

  public static async deleteValue (table: IDB_TABLES, key: string): Promise<void> {
    await IndexedDb.initializeDb();
    await IndexedDb?.db?.delete(table, key);
  }

  public static async getAllKeys (table: IDB_TABLES): Promise<string[]> {
    await IndexedDb.initializeDb();
    return IndexedDb?.db?.getAllKeys(table) || [];
  }
}
