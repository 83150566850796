import { BrandTypeDO } from '@amp/data-objects/BrandDO';
import gql from 'graphql-tag';

export const GetBrandTypes = gql`
  query {
    getBrandTypes {
      id
      name
      group
      showWithBrandTypeId
      primaryColor
      secondaryColor
      beliefMatrixSteps {
        id
        brandTypeId
        statement
        category
        order
      }
      featureBrandTypes {
        enabled
        feature {
          featurePermissions {
            permission {
              id
            }
          }
        }
      }
    }
  }
`;

export interface IBrandTypeMapping {
  [brandTypeId: string]: BrandTypeDO[];
}
