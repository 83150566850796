import React from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import modalStyles from '@amp/styles/modal.module.scss';
import { AuthenticatedDownloadLink } from '@amp/components/common/authenticated-download-link/AuthenticatedDownloadLink';
import buttonStyles from '@amp/styles/buttons.module.scss';
import downloadIcon from '@amp/assets/images/download_bp.svg';
import calendarIcon from '@amp/assets/images/calendar.svg';
import styles from './UsageReportModal.module.scss';
import { DivButton } from '@amp/components/common/buttons/div-button/DivButton';
import { config } from '@amp/config/config';
import { useUsageReportModal } from '@amp/components/common/modals/usage-report-modal/UsageReportModal.hook';

const analyticsApiUrl = config.analyticsUrl || '';

export const UsageReportModal = () => {
  const {
    endDate,
    endDateRef,
    isUsageReportModalOpen,
    maxDate,
    minDate,
    onClose,
    onEndDateChange,
    onStartDateChange,
    startDate,
    startDateRef
  } = useUsageReportModal();

  return (
    <div>
      <Modal className={`${modalStyles.modal} ${modalStyles.smallPadding}`} size={'small'} open={isUsageReportModalOpen} onClose={onClose} closeIcon>
        <Modal.Content>
          <Form className={styles.form}>
            <div className={styles.datePickerInput}>
              <div className={styles.title}>
                Start date
              </div>
              <div>
                <DivButton onClick={() => startDateRef.current?.setFocus()}>
                  <img src={calendarIcon} className={styles.icon} alt={''}/>
                </DivButton>
                <DatePicker
                  selectsStart
                  ref={startDateRef}
                  selected={startDate}
                  onChange={onStartDateChange}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select a date"
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minDate}
                  maxDate={new Date()}
                />
              </div>
            </div>

            <div className={styles.datePickerInput}>
              <div className={styles.title}>
                End date
              </div>

              <DivButton onClick={() => endDateRef.current?.setFocus()}>
                <img src={calendarIcon} className={styles.icon} alt={''}/>
              </DivButton>
              <DatePicker
                selectsEnd
                ref={endDateRef}
                selected={endDate}
                onChange={onEndDateChange}
                dateFormat="MM/dd/yyyy"
                placeholderText="Select a date"
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </Form>

          <AuthenticatedDownloadLink
            method='POST'
            contentType='application/json'
            filename={`AMP_UsageReport-${Date.now()}.xlsx`}
            body={{
              endDate,
              startDate
            }}
            url={`${analyticsApiUrl}/download-usage-report`}
            disabled={!startDate || !endDate}
          >
            {(loading: boolean) => (
              <Button className={buttonStyles.smallBlueButton}
                disabled={loading || !startDate || !endDate}
                loading={loading}
              >
                {!loading && <img className={'bigIcon'} src={downloadIcon} alt={''}/>}
                Download All Data
              </Button>
            )}
          </AuthenticatedDownloadLink>
        </Modal.Content>

        <Modal.Actions className={modalStyles.actions}>
          <Button className={`${buttonStyles.bigBlueButton} ${styles.cancelButton}`} onClick={onClose}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
