import React, { useContext } from 'react';
import { Container, Dropdown, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import styles from './Header.module.scss';
import logo from '../../../assets/images/amp_logo_v2.svg';
import { content } from './Header.content';
import { RootContext } from '@amp/contexts/RootContext';
import { RoutePaths } from '@amp/components/_routing/route-paths';
import { HeaderButton } from '@amp/components/page-layout/header/header-button/HeaderButton';
import { HasResource } from '@amp/components/common/has-resource/HasResource';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NotificationPopup } from './notification-popup/NotificationPopup';
import { useNotifications } from '@amp/components/common/notifications/Notifications.hooks';
import { NOTIFICATIONS_RESOURCE } from '@amp/components/common/notifications/NotificationsProvider';
import { DownloadsPopup } from '@amp/components/page-layout/header/downloads-popup/DownloadsPopup';

export const Header: React.FC = () => {
  const { isOnline, isAuthenticatedUserAdmin, authenticatedUser, handleLogout, setIsUsageReportModalOpen } = useContext(RootContext);
  /**
   * @TODO: AMPD-2203 to disable notifications, to reinstate, uncomment the next two lines of code
   */
  // const { totalUnreadNotifications } = useNotifications();
  // const notificationBadge = totalUnreadNotifications > 999 ? '999+' : totalUnreadNotifications;

  return (
    <div className={styles.header}>
      <Container className={styles.container}>
        <Link to="/home">
          <img className={styles.logo} alt={content.logoImgAlt} src={logo} />
        </Link>

        <div className={styles.menuContainer}>
          <div data-cy="user-email" className={styles.email}>
            {authenticatedUser?.email}
            {authenticatedUser?.isTestUser ? ` | ${authenticatedUser.regionCode}` : ''}
          </div>

          {/* @TODO AMPD-2142 Fix during ServiceWorker refactor */}
          {/* {isOnline && <div className={flagStyles.smallGreenFlag}>Online</div>} */}
          {/* {!isOnline && <div className={flagStyles.smallRedFlag}>Offline</div>} */}

          <HasResource name={'canUseReportsV2'}>
            <DownloadsPopup
              trigger={
                <button className={styles.notificationButton} id={'downloads-header-button'}>
                  <Icon className={styles.notificationIcon} name="download" />
                </button>
              }
            />
          </HasResource>

          {/* @TODO: AMPD-2203 to disable notifications, to reinstate, uncomment */}
          {/* <HasResource name={NOTIFICATIONS_RESOURCE}>
            <NotificationPopup
              trigger={
                <button className={styles.notificationButton}>
                  <Icon className={styles.notificationIcon} name="bell" />
                  {typeof notificationBadge === 'number' && notificationBadge > 0 && (
                    <Label className={styles.notificationBadge} circular>{notificationBadge}</Label>
                  )}
                </button>
              }
            />
          </HasResource> */}

          <Dropdown text={content.menuLabel} className={styles.menuDropdown} icon={'chevron down'}>
            <Dropdown.Menu className={styles.menuContent}>
              {isAuthenticatedUserAdmin() && isOnline && (
                <Dropdown.Header className={styles.menuHeader}>ADMIN</Dropdown.Header>
              )}
              {isAuthenticatedUserAdmin() && isOnline && (
                <HasResource name="communicatingAdminMenuLink">
                  <Link to={RoutePaths.TacticAdmin}>
                    <Dropdown.Item text="Tactics" className={styles.menuItem} />
                  </Link>
                </HasResource>
              )}
              {isAuthenticatedUserAdmin() && isOnline && (
                <Link to={RoutePaths.UserAdmin}>
                  <Dropdown.Item text="Users" className={styles.menuItem} />
                </Link>
              )}
              {isAuthenticatedUserAdmin() && isOnline && (
                <Link to={RoutePaths.SurveyAdmin}>
                  <Dropdown.Item text="Surveys" className={styles.menuItem} />
                </Link>
              )}
              {/* @TODO: AMPD-2203 to disable notifications, to reinstate, uncomment */}
              {/* {isAuthenticatedUserAdmin() && isOnline && (
                <HasResource name={NOTIFICATIONS_RESOURCE}>
                  <Link to={RoutePaths.NotificationsAdmin}>
                    <Dropdown.Item text="Notifications" className={styles.menuItem} />
                  </Link>
                </HasResource>
              )} */}
              {isAuthenticatedUserAdmin() && isOnline && (
                <HeaderButton onClick={() => setIsUsageReportModalOpen(true)}>
                  <Dropdown.Item text="Usage Report" className={styles.menuItem} />
                </HeaderButton>
              )}
              {isAuthenticatedUserAdmin() && isOnline && (
                <Link to={RoutePaths.AccountAlignmentAdmin}>
                  <Dropdown.Item text="Account Alignment" className={styles.menuItem} />
                </Link>
              )}
              {isOnline && (
                <HasResource name="manageFeaturesMenuItem">
                  <Link to={RoutePaths.FeatureAdmin}>
                    <Dropdown.Item text="Features" className={styles.menuItem} />
                  </Link>
                </HasResource>
              )}
              {isAuthenticatedUserAdmin() && isOnline && (
                <Dropdown.Divider className={styles.menuDivider} />
              )}
              <HeaderButton onClick={() => handleLogout()}>
                <Dropdown.Item text={content.logout} className={styles.menuItemRed} />
              </HeaderButton>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Container>
    </div>
  );
};
