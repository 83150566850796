export enum CacheEntity {
  accountTypes = 'accountTypes',
  client = 'client',
  features = 'features',
  allFeatures = 'allFeatures',
  isCachedOnce = 'isCachedOnce',
  permissions = 'permissions',
  presentations = 'presentations',
  tactics = 'tactics',
  tacticAssets = 'tacticAssets',
  tacticAssetHotspots = 'tacticAssetHotspots',
  tacticAssetVideos = 'tacticAssetVideos',
  transactions = 'transactions',
}
