import React from 'react';
import styles from './DownloadErrorMessage.module.scss';
import { Message } from 'semantic-ui-react';
import { IDownloadErrorMessageProps } from './DownloadErrorMessage.types';

export const DownloadErrorMessage: React.FC<IDownloadErrorMessageProps> = (props) => {
  const { errorMessage, showScreenshotMessage } = props;

  return <Message error className={styles.errorMessage}>
    <Message.Header>Sorry!</Message.Header>
    <p>The file is not available for download. Please try again. Details:</p>
    <p>{errorMessage || 'Download error.'}</p>
    {showScreenshotMessage &&
      <p>
        Please send a screenshot of this page to <a href="mailto:AMPSupport@payersciences.com">AMPSupport@payersciences.com</a> and
        we will look into the issue immediately.
      </p>
    }
  </Message>;
};
