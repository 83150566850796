export type HeaderMenuItem = { label: string; route: string };

export type ContentModel = {
  menuLabel: string;
  logout: string;
  logoImgAlt: string;
};

export const content: ContentModel = {
  menuLabel: 'Menu',
  logoImgAlt: 'logo',
  logout: 'Logout'
};
