/**
 * NOTE: This file should be deleted/removed when we have refactored to no longer use FormView Component
 * These are all copied from the original FormView Component typings.
 * Check source codes for updates.
 */

export type ViewType =
  | 'brand-multiselect'
  | 'brand-selector'
  | 'button'
  | 'conditional-question'
  | 'criteria'
  | 'criteria-table'
  | 'custom-milestone'
  | 'custom-form-title'
  | 'custom'
  | 'date'
  | 'date-quarter'
  | 'draggable-card'
  | 'draggable-card-group'
  | 'dropdown'
  | 'edit-info'
  | 'email'
  | 'form-group'
  | 'goal-dropdown'
  | 'hidden-text'
  | 'info'
  | 'matrix-header'
  | 'matrix-radio'
  | 'multi-text'
  | 'multiple-select'
  | 'nested-question'
  | 'number'
  | 'percent'
  | 'percentage-of-lives'
  | 'quarter-selector'
  | 'question-section'
  | 'radio'
  | 'save-button'
  | 'section-title'
  | 'segmentation'
  | 'selection-text'
  | 'selection'
  | 'slide-bullet-subheading'
  | 'table'
  | 'text'
  | 'text-area'
  | 'timestamps';

export type SectionViewType = 'NORMAL' | 'CARD';

// tslint:disable-next-line: interface-over-type-literal
export type FormActionType = {
  create: FormView;
  update: FormView;
};

// tslint:disable-next-line: interface-over-type-literal
export type FormView = {
  id: string;
  version: string;
  sections: any[]; // Section[];
  mode: any; // FormViewMode;
  save: any; // FormSaveInfo | null;
  data: any; // FormDataInfo | null;
};

export enum NumberInputStates {
  EMPTY = '',
  UNKNOWN = 'UNKNOWN'
}
