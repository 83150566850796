export const isNumeric = (n: number | string | undefined): boolean => {
  if (typeof n === 'number') {
    return true;
  }

  if (!n) {
    return false;
  }

  const converted = parseFloat(n);

  return !isNaN(converted);
};
