import { useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router';
import { useRecoilState } from 'recoil';

import { RootContext } from '@amp/contexts/RootContext';
import { API_TOKEN_CHECK } from '@amp/graphql/queries/ApiTokenCheck';
import { hasAccessToPath } from '@amp/permissions/permissions.helper';
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
import { NotificationsProvider } from '@amp/components/common/notifications/NotificationsProvider';
import { DownloadReportsContextProvider } from '@amp/contexts/DownloadReportsContext';

import { RoutePaths } from './route-paths';
import { initialRouteState } from './Routing.atoms';

import { PageLayout } from '../page-layout/PageLayout';

interface PrivateRouteProps extends React.PropsWithChildren<any> {
  Layout?: any,
  Component: any,
  path: string,
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  Component,
  Layout = PageLayout,
  path,
  ...rest
}) => {
  const location = useLocation();
  const [, setInitialRoute] = useRecoilState(initialRouteState);
  const { isAuthenticated, authenticatedUser } = useContext(RootContext);

  useQuery(API_TOKEN_CHECK, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    const initialRoute = location.pathname + location.search;
    if (!isEmpty(initialRoute)) {
      setInitialRoute(initialRoute);
    }
  }, [setInitialRoute, location.pathname, location.search]);

  if (path !== RoutePaths.ChangeExpiredPassword && authenticatedUser?.passwordExpired) {
    return <Redirect to={RoutePaths.ChangeExpiredPassword} />;
  }

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: RoutePaths.Login }} />;
  }

  if (!hasAccessToPath(path) && path !== RoutePaths.ChangeExpiredPassword && path !== RoutePaths.Dashboard) {
    return <Redirect to={{ pathname: RoutePaths.Dashboard }} />;
  }

  if (path === RoutePaths.ChangeExpiredPassword) {
    return <Route
      {...rest}
      render={matchProps => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />;
  }

  return (
    <DownloadReportsContextProvider>
      {/* @TODO: AMPD-2203 to disable notifications, to reinstate, uncomment */}
      {/* <NotificationsProvider> */}
      <Route
        {...rest}
        render={matchProps => (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )}
      />
      {/* @TODO: AMPD-2203 to disable notifications, to reinstate, uncomment */}
      {/* </NotificationsProvider> */}
    </DownloadReportsContextProvider>
  );
};
